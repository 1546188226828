import { useSelector } from "react-redux"
import { selectAddressesObject } from "../../../../../redux/slices/settings"

export default function MoveAddressCell({ destinationId }: { destinationId: string }) {
  const addresses = useSelector(selectAddressesObject);
  const address = destinationId && destinationId !== '' ? addresses[destinationId] : null;
  const name = address ? address?.data?.samsara_name : '';
  return (
    <div>{`${name}`}</div>
  )
}
