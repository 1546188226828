import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Card } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

// import { MoreHorizontal } from "react-feather";
import { Move } from "../../../../redux/models/trip.models";
import { selectAddressesObject, selectFleetObject } from "../../../../redux/slices/settings";
import { DateService } from "../../../../utils/dateService";
import { getTimeliness } from "../../../../utils/timeliness";
import { capitalizeFirstLetter } from "../../../../utils/core.utils";
import ButtonSpinner from "../../../../components/shared/ButtonSpinner";
import { Api } from "../../../../services/services";
import { StatDateProps } from "../../models/Stat";
import Analytics from "../../../../utils/analytics";

const PAGE_SIZE = 10;

const formatDate = (date: string, isSameDay: boolean) => {
  if (isSameDay) return DateService.getHHmmFormat(date);
  return DateService.getDoMMMHHmmFormat(date);
}

function Appointments({ dateFrom, dateTo }: StatDateProps) {
  const { orgCode } = useParams();
  const addresses = useSelector(selectAddressesObject);
  const fleet = useSelector(selectFleetObject);
  const [moves, setMoves] = useState<Move[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const isSameDay = DateService.isSame(dateFrom, dateTo);
  const canLoadMore = moves.length < total;
  const loadNextPage = async () => {
    try {
      const response = await Api.Dashboard.moveAppointments({
        start_date: dateFrom,
        end_date: dateTo,
        page,
        page_size: PAGE_SIZE,
      });
      if (response.status === 200) {
        const moveData = response.data.data.items || [];
        setPage(page + 1);
        setMoves([...moves, ...moveData]);
      }
    } catch (error) {
      Analytics.capture(error);
    }
  }
  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const response = await Api.Dashboard.moveAppointments({
        start_date: dateFrom,
        end_date: dateTo,
        page: 0,
        page_size: PAGE_SIZE,
      });
      if (response.status === 200) {
        const moveData = response.data.data.items || [];
        const count = response.data.data.total_count || 0;
        setTotal(count);
        setMoves(moveData);
      }
      setIsLoading(false);
    };
    load();
  }, [dateFrom, dateTo]);
  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        {/* <div className="card-actions float-end">
          <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <MoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>Action</Dropdown.Item>
              <Dropdown.Item>Another Action</Dropdown.Item>
              <Dropdown.Item>Something else here</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div> */}
        <Card.Title className="mb-0">Appointments</Card.Title>
      </Card.Header>
      <Card.Body className="d-flex">
        {isLoading && (
          <ButtonSpinner />
        )}
        {!isLoading && (
          <ul className="timeline overflow-auto" style={{ maxHeight: '500px' }}>
            {moves.map((move) => {
              const addressId = move.data.destination_id || '';
              const destination = addresses[addressId]?.data.samsara_name || 'No name set';
              const apptStr = move.data.scheduled_arrival_time;
              const appt = apptStr ? formatDate(apptStr, isSameDay) : '';
              const action = move.data.action || '';
              const trailerIds = move.data.trailer_ids || [];
              const shipmentIds = move.data.shipment_ids || [];
              const shipments = shipmentIds.length === 1 ? 'shipment' : 'shipments';
              const scheduled = move.data.scheduled_arrival_time;
              const arrival = move.data.actual_arrival_time;
              const tripId = move.data.trip_id || '';
              const timeliness = getTimeliness(scheduled, arrival) || '';
              const trailers = trailerIds.reduce((store, trailerId) => {
                const isSelected = trailerId !== '';
                const name = isSelected 
                  ? fleet[trailerId]?.data.name || '[no trailer name set]' : 'no trailer selected';
                if (store === '') return name;
                return `${store}, ${name}`;
              }, '');
              const details =
                `${capitalizeFirstLetter(action)} ${trailers} with ${shipmentIds.length} ${shipments}. ${timeliness}`;
              return (
                <li key={move.entity_id} className="timeline-item">
                  <Link to={`/${orgCode}/trips/${tripId}`}>
                    <strong>{destination}</strong>
                  </Link>
                  <span className="float-end text-muted text-sm">{appt}</span>
                  <p>{details}</p>
                </li>
              );
            })}
          </ul>
        )}
        
      </Card.Body>
      <Card.Footer>
        {!isLoading && canLoadMore && (
          <div className="d-grid">
            <Button variant="primary" onClick={loadNextPage}>Load more</Button>
          </div>
        )}
      </Card.Footer>
    </Card>
  );
}

export default Appointments;
