import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FileText } from 'react-feather';
import AddModal from '../../../../components/modals/AddModal';
import { Document } from '../../../../redux/models/file.models';
import { SelectInput } from '../../../../components/shared/Input';
import { ShipmentServiceApiProvider } from '../../../../services/ShipmentServiceProvider';
import { Shipment } from '../../../../redux/models/shipment.models';
import {
  ShipmentDocKey,
  createShipmentDocOptions,
  assignDocs,
} from '../../../trips/Moves/components/AssignDocModal.utils';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import { updateShipmentDetails } from '../../../../redux/slices/shipments';
import {
  updateShipmentDetails as updateShipmentDetailsPaginated
} from '../../../../redux/slices/shipment-list/shipment-list';
import useFeatureFlags from '../../../../hooks/useFeatureFlags';

interface Props {
  shipment: Shipment;
  document: Document;
  documentIdx: number;
  shouldShowModal: boolean;
  handleSave: () => void;
  cancelSave: () => void;
}

export default function AssignDocModal({
  shipment,
  document,
  documentIdx,
  shouldShowModal,
  handleSave,
  cancelSave,
}: Props) {
  const dispatch = useAppDispatch();
  const features = useFeatureFlags();
  const [shipmentDocId, setShipmentDocId] = useState<string>('');
  const [isSaving, setIsSaving] = useState(false);
  const deliveryDocs = shipment?.data.delivery_attachments || [];
  const pickUpDocs = shipment?.data.pick_up_attachments || [];
  const miscDocs = shipment?.data.misc_attachments || [];
  const isValid = shipmentDocId !== '';
  const isPaginationEnabled = features.paginated_shipments?.is_enabled || false;
  const shipmentDocOptions = [
    ...createShipmentDocOptions(deliveryDocs, ShipmentDocKey.Delivery),
    ...createShipmentDocOptions(pickUpDocs, ShipmentDocKey.PickUp),
    ...createShipmentDocOptions(miscDocs, ShipmentDocKey.Misc),
  ];
  const handleSelectShipmentDoc = (_: string, value: string) => {
    setShipmentDocId(value);
  };
  const onSave = async () => {
    if (!isValid || !shipment) return;
    const moveDoc = document;
    const shipmentUpdates: Shipment = {
      ...shipment,
      data: {
        ...shipment.data,
        delivery_attachments: assignDocs(deliveryDocs, shipmentDocId, ShipmentDocKey.Delivery, moveDoc),
        misc_attachments: assignDocs(miscDocs, shipmentDocId, ShipmentDocKey.Misc, moveDoc),
        pick_up_attachments: assignDocs(pickUpDocs, shipmentDocId, ShipmentDocKey.PickUp, moveDoc),
      },
    };
    setIsSaving(true);
    const response = await ShipmentServiceApiProvider.updateShipment(shipmentUpdates);
    setIsSaving(false);
    if (response.status === 200) {
      if (isPaginationEnabled) {
        dispatch(updateShipmentDetailsPaginated(response.data.data));
      } else {
        dispatch(updateShipmentDetails(response.data.data));
      }
      handleSave();
    } else {
      toast("Couldn't Save Shipment", { type: 'error' });
    }
  };
  return (
    <AddModal
      title="Document Assignment"
      btnTitle="Save"
      saveDisabled={!isValid}
      shouldShowModal={shouldShowModal}
      isSaving={isSaving}
      handleSave={onSave}
      cancelSave={cancelSave}
    >
      <Form>
        <h4>Document</h4>
        <div>
          <FileText className="feather align-middle" />{" "}
          {`${documentIdx + 1}. ${document.name}`}
        </div>
        <div className="mt-4">
          <div>
            <h4>Shipment Documents</h4>
            <SelectInput
              name="shipment_docs"
              page="page"
              value={shipmentDocId}
              options={shipmentDocOptions}
              shouldHaveMargin={false}
              handleChange={handleSelectShipmentDoc}
              dataCy="move_shipment_id"
              disabled={false}
              labelText=""
            />
          </div>
        </div>
      </Form>
    </AddModal>
  );
}
