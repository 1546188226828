/* eslint-disable */
import { EntityServiceProvider } from './EntityServiceProvider';
import { ShipmentData } from '../../redux/models/shipment.models';
import { AquadashServiceConfigProvider } from '../AquadashServiceConfigProvider';
import { DataModel } from '../../redux/models/core.models';
import { Document } from '../../redux/models/file.models';

// DELETE /shipment/<string:shipment_id>/trips - Remove a shipment and all its trips
class ShipmentServiceProvider extends EntityServiceProvider<ShipmentData> {
  duplicate(shipmentId: string, trips: boolean) {
    const base = `${this.endpoint.entityPath()}/duplicate/${shipmentId}`;
    const url = trips ? `${base}/trips` : base;
    return this.api.post(url);
  }

  list(filters: any) {
    return this.api.get(AquadashServiceConfigProvider.getShipments(filters));
  }

  listPaginated(filters: any, page: number, pageSize: number) {
    const pg = page ? page : 0;
    const pgSize = pageSize ? pageSize : 25;
    return this.api.get(AquadashServiceConfigProvider.getShipmentsPaginated(filters, pg, pgSize));
  }

  listDetails(filters: any) {
    return this.api.get(AquadashServiceConfigProvider.getShipmentsAllDetails(filters));
  }
  
  getDetailsById(shipmentId: string) {
    return this.api.get(AquadashServiceConfigProvider.getShipmentDetails(shipmentId));
  }

  addDocument(shipmentId: string, document: Document) {
    const url = `${AquadashServiceConfigProvider.getShipmentDetails(shipmentId)}/share/documents`;
    return this.api.put(url, document);
  }

  deleteShipment(shipment: DataModel<ShipmentData>, trips: boolean) {
    if (trips) {
      const url = `${this.endpoint.entityPath()}/${shipment.entity_id}/trips`;
      return this.api.delete(url);
    }
    return this.delete(shipment)
  }

  createTrip(shipmentIds: string[]) {
    const url = `${this.endpoint.entityPath()}/create/trip`;
    return this.api.post(url, { shipment_ids: shipmentIds });
  }
}

export default ShipmentServiceProvider;
