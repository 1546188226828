/* eslint-disable import/no-cycle */
import { EntityContainer } from '../redux/models/core.models';
import { AquadashServiceApiProvider } from '../services/AquadashServiceApiProvider';
import { Api } from '../services/services';

export const FILTER_STORAGE_PREFIX = 'dashboard-';
export const SETTINGS_STORAGE_PREFIX = 'settings-';
export const REFRESH_SETTINGS_HOURS = 10;

export const SETTINGS_NAMES = {
  ADDRESSES: 'addresses',
  BOARDS: 'boards',
  GROUPS: 'groups',
  CONTACTS: 'contacts',
  COSTS: 'costs',
  NOTE_TEMPLATES: 'note_templates',
  DRIVERS: 'drivers',
  VEHICLES: 'vehicles',
  FLEET: 'fleets',
  BILLING_ITEMS: 'billing_items',
  BILLING_TYPES: 'billing_types',
  UOMS: 'uoms',
  PRODUCTS: 'products',
  SHIPMENT_TYPES: 'shipment_types',
  SHIPPING_LINES: 'shipping_lines',
  TAGS: 'tags',
  TRIP_TEMPLATES: 'trip_templates',
};

export const getSettingName = (key: string) => {
  const names: EntityContainer<string> = {
    addresses: 'Addresses',
    boards: 'Boards',
    groups: 'Groups',
    contacts: 'Contacts',
    costs: 'Costs',
    note_templates: 'Note Templates',
    drivers: 'Drivers',
    vehicles: 'Vehicles',
    fleets: 'Fleet',
    billing_items: 'Rates',
    billing_types: 'Quote Templates',
    uoms: 'Units Of Measure',
    products: 'Products',
    shipment_types: 'Shipment Types',
    shipping_lines: 'Shipping Lines',
    tags: 'Tags',
    trip_templates: 'Trip Templates',
  };
  return names[key] || key;
}

export const SETTINGS_MANAGER = {
  [SETTINGS_NAMES.ADDRESSES]: AquadashServiceApiProvider.syncAddresses,
  [SETTINGS_NAMES.BILLING_ITEMS]: AquadashServiceApiProvider.syncBillingItems,
  [SETTINGS_NAMES.BILLING_TYPES]: AquadashServiceApiProvider.syncBillingTypes,
  [SETTINGS_NAMES.BOARDS]: () => Api.Boards.getAll(),
  [SETTINGS_NAMES.CONTACTS]: AquadashServiceApiProvider.syncContacts,
  [SETTINGS_NAMES.COSTS]: () => Api.Contacts.getAll(),
  [SETTINGS_NAMES.DRIVERS]: AquadashServiceApiProvider.syncDrivers,
  [SETTINGS_NAMES.FLEET]: AquadashServiceApiProvider.syncFleet,
  [SETTINGS_NAMES.GROUPS]: AquadashServiceApiProvider.syncGroups,
  [SETTINGS_NAMES.NOTE_TEMPLATES]: AquadashServiceApiProvider.getNoteTemplates,
  [SETTINGS_NAMES.PRODUCTS]: AquadashServiceApiProvider.getProducts,
  [SETTINGS_NAMES.SHIPMENT_TYPES]: () => Api.ShipmentTypes.getAll(),
  [SETTINGS_NAMES.SHIPPING_LINES]: () => Api.ShippingLines.getAll(),
  [SETTINGS_NAMES.TAGS]: AquadashServiceApiProvider.getTags,
  [SETTINGS_NAMES.TRIP_TEMPLATES]: AquadashServiceApiProvider.getTemplates,
  [SETTINGS_NAMES.UOMS]: AquadashServiceApiProvider.syncUoms,
  [SETTINGS_NAMES.VEHICLES]: AquadashServiceApiProvider.syncVehicles,
};
