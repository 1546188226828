import { useNavigate, useParams } from 'react-router-dom';
import { INVOICE_TABLE_HEADERS } from '../constants/invoicesTable.constants';

import InvoiceTableComponent from './InvoiceTableComponent';

export default function InvoiceTable({
  formattedInvoices,
  headers = INVOICE_TABLE_HEADERS,
}) {
  const { orgCode } = useParams();
  const navigate = useNavigate();
  const openInvoicePage = (id) => navigate(`/${orgCode}/invoices/${id}`);

  return (
    <div className="bg-white">
      <InvoiceTableComponent
        headers={headers}
        formattedInvoices={formattedInvoices}
        openInvoicePage={openInvoicePage}
      />
    </div>
  );
}
