import { MoveData, TripBoardModel } from "../../../../../redux/models/trip.models";
import { DateService } from "../../../../../utils/dateService";

const formatApt = (move: MoveData, isDateRange: boolean) => {
  if (move.schedule_tbd) {
    return <div>TBD</div>
  }
  const moveDateStr = move.scheduled_arrival_time || '';
  const dt = isDateRange ? DateService.getDoMMMHHmmFormat(moveDateStr) : DateService.getHHmmFormat(moveDateStr);
  if (dt.includes('Invalid')) {
    console.log('moveDateStr: ', moveDateStr);
    console.log('dt: ', dt);
  }
  return <div>{dt}</div>;
}

interface Props {
  boardDate: string;
  isDateRange: boolean;
  trip: TripBoardModel;
}

function TripAptCell({ trip, boardDate, isDateRange }: Props) {
  const moveData = trip.moves || [];
  const moves = moveData.filter((mv) => {
    const arrival = mv.scheduled_arrival_time || '';
    return DateService.isSame(arrival, boardDate);
  });
  const appt = moves.find((move) => move.show_appt);
  if (appt) {
    return formatApt(appt, isDateRange);
  }
  if (moves.length > 0) {
    return formatApt(moves[0], isDateRange);
  }
  return <div>Not Set</div>;
}

export default TripAptCell;