/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Row, Spinner, Stack } from 'react-bootstrap';
import {
  getSettingByName,
  getTripTemplate,
  selectAddresses,
  selectBillingItems,
  selectBillingTypesByCustomer,
  selectContacts,
  selectProducts,
  selectShipmentTypes,
  selectShippingLines,
  selectUoms,
  selectWeightUoms,
  updateTripTemplate,
} from '../../redux/slices/settings';
import { TripTemplate } from '../../redux/models/settings.models';

import useAppDispatch from '../../hooks/useAppDispatch';
import { SETTINGS_NAMES } from '../../constants/core.constants';
import ShipmentForm from '../shipments/ShipmentDetails/ShipmentForm/ShipmentForm';
import { getDefaultDataModel } from '../../redux/models/core.models';

import { createDropdownOptions } from '../../utils/core.utils';
import { getFields } from '../shipments/utils/shipments.utils';
import { Shipment, ShipmentData } from '../../redux/models/shipment.models';
import BreadCrumbs, { BreadCrumbRoute } from '../../components/shared/breadcrumbs/BreadCrumbs';
import { selectUserContainer } from '../../redux/slices/organization/organisation';

const getRoutes = (
  orgCode: string | undefined,
  template: TripTemplate | null,
  key: string | undefined): BreadCrumbRoute[] => {
  return [
    {
      name: 'Settings',
      route: `/${orgCode || ''}/settings`,
    },
    {
      name: `${template?.data.name || ''}`,
      route: `/${orgCode}/settings/trip-templates/${template?.entity_id || ''}`,
    },
    {
      name: `Shipment`,
      route: `/${orgCode}/settings/trip-templates/${template?.entity_id || ''}/shipments/${key || ''}`,
    },
  ];
};

const getShipmentDetails = (shipments: ShipmentData[], key: string | undefined) => {
  if (key === undefined) return null;
  const keyId = parseInt(key, 2);
  if (Number.isNaN(keyId) || keyId > shipments.length) return null;
  const shipment = shipments[keyId];
  return getDefaultDataModel(shipment);
};

export default function TripTemplateShipmentEditor() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orgCode, templateId, key } = useParams();
  const [template, setTemplate] = useState<TripTemplate | null>(null);
  const [shipmentDetails, setShipmentDetails] = useState<Shipment | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const users = useSelector(selectUserContainer);
  const contacts = useSelector(selectContacts);
  const addresses = useSelector(selectAddresses);
  const shippingLines = useSelector(selectShippingLines);
  const billingItems = useSelector(selectBillingItems);
  const uoms = useSelector(selectUoms);
  const weightUoms = useSelector(selectWeightUoms);
  const products = useSelector(selectProducts);
  const shipmentTypes = useSelector(selectShipmentTypes);
  const customerId = shipmentDetails?.data?.customer_id || '';
  const billingTypes = useSelector((state: any) => selectBillingTypesByCustomer(state, customerId));
  const ports = addresses.filter(
    (address) => address.data.port,
  );

  const portSelectOptions = createDropdownOptions(ports, 'samsara_name')
    .concat([{ value: 'dock-pickup', label: 'Off - Dock Pickup' }]);
  const addressSelectOptions = createDropdownOptions(addresses, 'samsara_name', 'samsara_formatted_address');
  const uomSelectOptions = createDropdownOptions(uoms);
  const weightUomSelectOptions = createDropdownOptions(weightUoms);
  const customerSelectOptions = createDropdownOptions(contacts);
  const shipmentTypeOptions = createDropdownOptions(shipmentTypes);
  const shippingLineOptions = createDropdownOptions(shippingLines);
  const userDropdownOptions = createDropdownOptions(Object.values(users));

  const handleChange = (ship: any) => {
    if (!template) return;
    setShipmentDetails(ship);
  };
  
  const handleClose = () => navigate(-1);
  const handleSave = async () => {
    if (!template || shipmentDetails === null) return;
    try {
      setIsSaving(true);
      const templateData: TripTemplate = {
        ...template,
        data: {
          ...template.data,
          shipments: template.data.shipments.map((ship, idx) => {
            if (`${idx}` === key) return shipmentDetails.data;
            return ship;
          })
        }
      }
      const response = await updateTripTemplate(templateData);
      if (response.status === 200) {
        await dispatch(getSettingByName(SETTINGS_NAMES.TRIP_TEMPLATES));
        handleClose();
      }
    } catch (error) {
      toast(`Couldn't save ${template?.data.name || 'template'} shipment`, { type: 'error' });
    } finally {
      setIsSaving(false);
    }
  };
  useEffect(() => {
    const handleLoad = async (id: string, keyId: string) => {
      try {
        const response = await getTripTemplate(id);
        if (response.status === 200) {
          const templateData = response.data.data;
          setTemplate(templateData);
          const shipments = templateData?.data?.shipments || [];
          const ship = getShipmentDetails(shipments, keyId);
          setShipmentDetails(ship);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
    if (templateId && key) handleLoad(templateId, key);
  }, [templateId, key]);
  
  if (isLoading) return <Spinner animation="border" variant="light" />;
  return (
    <div>
      <BreadCrumbs routes={getRoutes(orgCode, template, key)} />
      <h4>{`${template?.data.name || ''} Template Shipment`}</h4>
      <Row>
        {shipmentDetails && shipmentDetails.data && (
          <ShipmentForm
            shipmentDetails={shipmentDetails}
            billingTypes={billingTypes}
            billingItems={billingItems}
            products={products}
            shipmentTypeEntityOptions={shipmentTypeOptions}
            shippingLineOptions={shippingLineOptions}
            isEditable
            validation={{}}
            fields={getFields(shipmentDetails, shipmentTypes)}
            customerSelectOptions={customerSelectOptions}
            addressSelectOptions={addressSelectOptions}
            portSelectOptions={portSelectOptions}
            uomSelectOptions={uomSelectOptions}
            userSelectOptions={userDropdownOptions}
            weightUomSelectOptions={weightUomSelectOptions}
            onUpdate={handleChange}
          />
        )}
      </Row>
      <Row>
        <Stack direction="horizontal" gap={3} className="float-end justify-content-end">
          <Button variant="secondary" onClick={handleClose}>Cancel</Button>
          <Button variant="primary" onClick={handleSave}>Save
            {isSaving && (
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                style={{ marginLeft: '4px' }}
              />
            )}
          </Button>
        </Stack>
      </Row>
    </div>
  );
}
