import React from 'react'
import ShipmentList from './ShipmentList'
import useFeatureFlags from '../../hooks/useFeatureFlags'
import ShipmentListView from './list-view/ShipmentListView';

export default function ShipmentListContainer() {
  const { paginated_shipments: pagination } = useFeatureFlags();
  const isEnabled = pagination?.is_enabled ?? false;
  return isEnabled ? <ShipmentListView /> : <ShipmentList />
};
