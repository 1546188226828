import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Badge,
  Button,
  Container,
  Row,
  Stack,
  Form,
  Card,
} from 'react-bootstrap';
import { toast } from 'react-toastify';

import useAppDispatch from '../../hooks/useAppDispatch';
import {
  deleteBoard,
  getSettingByName, saveBoard, selectBoard, selectGroupsByBoardId, selectIsSettingsLoaded, selectNoteTemplates
} from '../../redux/slices/settings';

import { EntityContainer } from '../../redux/models/core.models';
import { AppState } from '../../redux/models/state.models';
import { Tag } from '../../redux/models/settings.models';
import { TRIP_TABLE_HEADERS } from '../boards/TripTable/constants/tripTable.constants';
import { Board, TripTableColumns } from '../../redux/models/board.models';
import Analytics from '../../utils/analytics';
import { SETTINGS_NAMES } from '../../constants/core.constants';
import { createDropdownOptions } from '../../utils/core.utils';
import { SelectInput } from '../../components/shared/Input';
import ShareButton from '../../components/permissions/ShareButton';
import AsyncButton from '../../components/shared/buttons/AsyncButton';
import ActionModal from '../../components/modals/ActionModal';
import MessageModal from '../../components/modals/MessageModal';

const INTEGRATIONS: EntityContainer<string> = {
  'eta-email': 'Eta Email',
  'ferry-email': 'BCFerries',
}

interface TagListProps {
  title: string;
  tags: Tag[];
}

function TagList({ title, tags }: TagListProps) {
  return (
    <>
      <h5 data-cy={`board_settings_badge_title_${title}`}>{title || ''}</h5>
      <Stack direction="horizontal" gap={2}>
        {tags.map((tag) => <Badge key={tag.entity_id} pill bg="success">{tag.data.name || 'No name set'}</Badge>)}
        {tags.length === 0 && <p>{`No ${title.toLocaleLowerCase()}`}</p>}
      </Stack>
    </>
  )
}

const getDefaultTemplateId = (board: Board | undefined) => {
  const notes = board?.data.note_templates || [];
  if (notes.length < 1) return '';
  return notes[0].entity_id;
};

export default function BoardSettings() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { boardId, orgCode } = useParams();
  const board = useSelector((state: AppState) => selectBoard(state, boardId));
  const groups = useSelector((state: AppState) => selectGroupsByBoardId(state, boardId || ''));
  const templates = useSelector(selectNoteTemplates);
  const isLoaded = useSelector(selectIsSettingsLoaded);
  const defaultTemplateId = getDefaultTemplateId(board);
  const [selectedTemplateId, setSelectedTemplateId] = useState(defaultTemplateId);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showDeleteGroups, setShowDeleteGroups] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [groupTableCols, setGroupTableCols] = useState<TripTableColumns[]>([]);
  const [driverTableCols, setDriverTableCols] = useState<TripTableColumns[]>([]);
  const defaultRoles = board?.data.role_ids || board?.roles || [];
  const [boardRoles, setBoardRoles] = useState<string[]>(defaultRoles);
  // const [isSaving, setSaving] = useState(false);
  const [isError, setError] = useState(false);
  const boardDriverCols = board?.data?.table_config?.driver_table || [];
  const boardGroupCols = board?.data?.table_config?.group_table || [];
  const name = board?.data?.name || '';
  const options = createDropdownOptions(templates || []);
  const isTemplates = templates.length > 0;
  const TITLE = name ? `${name} Board Settings` : 'Board Settings';
  const integrations = board ? board.data?.integrations || [] : [];
  const driverTags = board ? board?.data?.driver_tags || [] : [];
  const fleetTags = board ? board?.data?.fleet_tags || [] : [];
  const vehicleTags = board ? board?.data?.vehicle_tags || [] : [];
  
  const handleSelectTemplate = (prop: string, value: string) => {
    setSelectedTemplateId(value);
  };
  const getTableColVal = (key: string, cols: TripTableColumns[]) => {
    const id = key || '';
    if (cols) return cols?.find((col) => col?.key === id)?.visible || false
    return false;
  };
  const handleSelectGroupCol = (value: boolean, col: TripTableColumns) => {
    const updates = groupTableCols.map((stateCol) => {
      return {
        ...stateCol,
        visible: col.key === stateCol.key ? value : stateCol.visible,
      };
    });
    setGroupTableCols(updates);
  };
  const handleSelectDriverCol = (value: boolean, col: TripTableColumns) => {
    const updates = driverTableCols.map((stateCol) => {
      return {
        ...stateCol,
        visible: col.key === stateCol.key ? value : stateCol.visible,
      };
    });
    setDriverTableCols(updates);
  };
  const handleUpdateRoles = (roleIds: string[]) => {
    setBoardRoles(roleIds);
  };
  const handleSave = async () => {
    try {
      if (!board) return;
      const template = templates.find((temp) => temp.entity_id === selectedTemplateId);
      const updates: Board = {
        ...board,
        data: {
          ...board?.data,
          note_templates: template ? [template] : [],
          role_ids: boardRoles,
          table_config: {
            driver_table: driverTableCols,
            group_table: groupTableCols,
          },
        },
      };
      // setSaving(true);
      await saveBoard(updates);
      await dispatch(getSettingByName(SETTINGS_NAMES.BOARDS));
      navigate(-1);
    } catch (error) {
      Analytics.capture(error);
      if (error instanceof Error) {
        const message =
          `Couldn't update board settings. ${error.message}. Please contact support if the problem persists.`;
        toast(message, { type: 'error' });
        setError(true);
      }
    } finally {
      // setSaving(false);
    }
  }

  const handleConfirmDelete = () => {
    if (groups.length === 0) {
      setShowConfirmDelete(true);
    } else {
      setShowDeleteGroups(true);
    }
  };

  const handleDelete = async () => {
    try {
      if (!board) return;
      setIsDeleting(true);
      await deleteBoard(board);
      await dispatch(getSettingByName(SETTINGS_NAMES.BOARDS, false));
      navigate(`/${orgCode}/boards`);
    } catch (error) {
      Analytics.capture(error);
      if (error instanceof Error) {
        const message =
          `Couldn't delete board. ${error.message}. Please contact support if the problem persists.`;
        toast(message, { type: 'error' });
        setError(true);
      }
    } finally {
      setIsDeleting(false);
    }
  };

  useEffect(() => {
    const defaultDriverVals = TRIP_TABLE_HEADERS.map((defaultCol) => {
      return {
        ...defaultCol,
        visible: boardDriverCols.find((boardCol) => boardCol.key === defaultCol.key)?.visible || false,
      };
    });
    const defaultGroupVals = TRIP_TABLE_HEADERS.map((defaultCol) => {
      return {
        ...defaultCol,
        visible: boardGroupCols.find((boardCol) => boardCol.key === defaultCol.key)?.visible || false,
      };
    });
    setDriverTableCols(defaultDriverVals);
    setGroupTableCols(defaultGroupVals);
  }, [board]);

  return (
    <>
      <Helmet title={TITLE} />
      <Container fluid className="p-0">
        <Stack direction="horizontal" gap={3}>
          <h3 className="mt-1" data-cy="board_settings_title">{TITLE}</h3>
          <ShareButton roleIds={boardRoles} onUpdateRoles={handleUpdateRoles} />
        </Stack>
        <Card>
          <Card.Header as="h4">Tags</Card.Header>
          <Card.Body>
            <Row>
              <TagList title="Driver Tags" tags={driverTags} />
            </Row>
            <Row className="mt-2">
              <TagList title="Fleet Tags" tags={fleetTags} />
            </Row>
            <Row className="mt-2">
              <TagList title="Vehicle Tags" tags={vehicleTags} />
            </Row>
          </Card.Body>
        </Card>        
        {isTemplates && isLoaded && (
          <Card>
            <Card.Header as="h4">Dispatch Notes Template</Card.Header>
            <Card.Body>
              <SelectInput
                name="template"
                page="move_panel"
                value={selectedTemplateId}
                options={options}
                shouldHaveMargin={false}
                handleChange={handleSelectTemplate}
                dataCy="move_panel_notes_templates"
                labelText=""
                disabled={false}
                multiple={false}
                isClearable
                isSearchable
              />
            </Card.Body>
          </Card>            
        )}
        <Card className="mt-2">
          <Card.Header as="h4">Integrations</Card.Header>
          <Card.Body>
            {integrations.map((integration) => <p key={integration}>{INTEGRATIONS[integration] || 'No name set'}</p>)}
            {integrations.length === 0 && <p>No integrations</p>}
          </Card.Body>
        </Card>
        {isLoaded && (
          <Card className="mt-2">
            <Card.Header as="h4">Group Table Columns</Card.Header>
            <Card.Body>
              <div className="d-flex flex-wrap">
                {groupTableCols.map((header) => (
                  <Stack
                    key={header.key}
                    direction="horizontal"
                    className="align-items-center bg-light m-2 p-2 rounded"
                  >
                    <h5 className="my-1 mx-2">{header.name}</h5>
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="custom-switch"
                      label=""
                      className="ml-2"
                      data-cy={`board_settings_table_col_title_${header.key}`}
                      checked={getTableColVal(header?.key || '', groupTableCols)}
                      onChange={(e) => handleSelectGroupCol(e.target.checked, header)}
                    />
                  </Stack>
                ))}
              </div>
            </Card.Body>
          </Card>
        )}
        {isLoaded && (
          <Card className="mt-2">
            <Card.Header as="h4">Driver Table Columns</Card.Header>
            <Card.Body>
              <div className="d-flex flex-wrap">
                {driverTableCols.map((header) => (
                  <Stack
                    key={header.key}
                    direction="horizontal"
                    className="align-items-center bg-light m-2 p-2 rounded"
                  >
                    <h5 className="my-1 mx-2">{header.name}</h5>
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="custom-switch"
                      label=""
                      className="ml-2"
                      data-cy={`board_settings_table_col_title_${header.key}`}
                      checked={getTableColVal(header?.key || '', driverTableCols)}
                      onChange={(e) => handleSelectDriverCol(e.target.checked, header)}
                    />
                  </Stack>
                ))}
              </div>
            </Card.Body>
          </Card>
        )}
        {isLoaded && (
          <Row className="mt-4">
            <div className="d-flex justify-content-between">
              <AsyncButton
                title="Delete"
                dataCy="board_settings_delete_btn"
                id="board_settings_delete_btn"
                variant="outline-danger"
                spinner="light"
                handleClick={handleConfirmDelete}
              />
              <Stack gap={2} className="float-end justify-content-end" direction="horizontal">
                <Button
                  data-cy="board_settings_cancel_btn"
                  variant="light"
                  onClick={() => navigate(-1)}>Back</Button>
                <AsyncButton
                  title="Save"
                  dataCy="board_settings_save_btn"
                  id="board_settings_save_btn"
                  spinner="light"
                  handleClick={handleSave}
                />
              </Stack>
            </div>
          </Row>
        )}
        <Row>
          {isError && <p data-cy="board_settings_error_tag">Error saving board</p>}
        </Row>
        {showConfirmDelete && (
          <ActionModal
            title={`Delete ${board?.data?.name || ''}`}
            description={`Are you sure you'd like to delete ${board?.data?.name || 'this board'}?`}
            shouldShowDeleteModal={showConfirmDelete}
            isDeleting={isDeleting}
            deleteBtnText="Delete"
            deleteItem={handleDelete}
            cancelDeletion={() => setShowConfirmDelete(false)}
          />
        )}
        {showDeleteGroups && (
          <MessageModal
            title={`Cannot delete ${board?.data?.name || 'this board'}`}
            page="BoardSettings"
            description="Must delete or re-assign groups before deleting board"
            showMessageModal={showDeleteGroups}
            closeMessage={() => setShowDeleteGroups(false)}
          />
        )}
      </Container>
    </>
  );
}
