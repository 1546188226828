import { TripBoardModel } from "../../../../../redux/models/trip.models";
import { TempIcon } from "../../../../icons/ShipmentIcons";

function TripTempCell({ trip }: { trip: TripBoardModel }) {
  const temps = trip.temp || [];
  const tripId = trip.id || '';
  return (
    <div>
      {temps.map((temp, idx) => (
        <TempIcon
          key={`tripsTable-${tripId}_${temp}_${idx}`}
          temp={temp.temp}
          setting={temp.setting}
          dry={trip.dry}
        />
      ))}
    </div>
  )
};

export default TripTempCell;
