/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Row, Stack } from 'react-bootstrap';

import useAppDispatch from '../../../hooks/useAppDispatch';
import ShipmentForm from './ShipmentForm/ShipmentForm';
import TripSummary from './TripSummary/TripSummary';
import DeleteModal from '../../../components/modals/DeleteModal';
import {
  selectAddresses,
  selectContacts,
  selectBillingTypesByCustomer,
  selectBillingItems,
  selectUoms,
  selectWeightUoms,
  selectProducts,
  selectShipmentTypes,
  selectShippingLines,
  getSettingByName,
} from '../../../redux/slices/settings';
import { saveShipmentDetails, updateShipmentDetails, duplicateShipment } from '../../../redux/slices/shipments';
import {
  resetShipments,
  saveShipmentDetails as saveShipmentDetailsPaginated,
  updateShipmentDetails as updateShipmentDetailsPaginated
} from '../../../redux/slices/shipment-list/shipment-list';
import { createDropdownOptions } from '../../../utils/core.utils';
import { getIsDeleting } from '../../../redux/slices/shared';
import Documents from './Documents/Documents';
import BillingCard from './Invoice/BillingCard';
import InvoiceCard from './Invoice/InvoiceCard';
import PageFooterButtons from '../../../components/shared/PageFooterBtns';
import { deleteInvoiceShipment } from '../../../redux/slices/invoices';
import { Shipment } from '../../../redux/models/shipment.models';
import Permission from '../../../components/shared/permissions/Permission';
import { FeatureResource, ResourcePermission } from '../../../redux/models/feature.flags.models';
import { isPermissions } from '../../../redux/slices/settings.utils';
import { getPermissions } from '../../../config';
import { TripListViewData } from '../../../redux/models/trip.models';
import DocumentsPanel from './Documents/DocumentsPanel';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import ShareButton from '../../../components/permissions/ShareButton';
import { getFields, getShipmentPageTitle, isShipmentValid } from '../utils/shipments.utils';
import { EntityContainer } from '../../../redux/models/core.models';
import ShipmentMenu from './components/ShipmentMenu';
import AddTripTemplateModal from '../../templates/AddTemplate/AddTripTemplateModal';
import Analytics from '../../../utils/analytics';
import { createTemplate } from '../../../redux/slices/templates';
import { SETTINGS_NAMES } from '../../../constants/core.constants';
import DuplicateShipmentModal from './components/DuplicateModal';
import DeleteShipmentModal from './components/DeleteShipmentModal';
import { CostReadPermissions } from '../../../components/shared/permissions/permissions.utils';
import ShipmentCostCard from './components/cost-card/ShipmentCostCard';
import { User } from '../../../redux/models/user.models';

const ReadTripPermissions = {
  [FeatureResource.Trip]: ResourcePermission.Read,
};

const ReadInvoicePermissions = {
  [FeatureResource.Invoice]: ResourcePermission.Read,
};

const WriteInvoicePermissions = {
  [FeatureResource.Invoice]: ResourcePermission.Write,
};

const WriteShipmentPermissions = {
  [FeatureResource.Shipment]: ResourcePermission.Write,
};

interface Props {
  shipmentDetails: Shipment;
  users: EntityContainer<User>;
}

export default function ShipmentDetails({ shipmentDetails, users }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const features = useFeatureFlags();
  const { orgCode } = useParams();
  const [shouldShowDeleteModal, setShouldShowDeleteModal] = useState(false);
  const [removeShipmentModal, setRemoveShipmentModal] = useState(false);
  const [showShipmentMenu, setShowShipmentMenu] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [isSavingTemplate, setIsSavingTemplate] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [formValidation, setFormValidation] = useState<EntityContainer<boolean>>({});
  
  const permissions = getPermissions();
  const isEditable = isPermissions(orgCode, permissions, WriteShipmentPermissions);
  const isInvoicesEnabled = features.invoices?.is_enabled || false;
  const isCostsEnabled = features.trip_costs?.is_enabled || false;
  const isPaginationEnabled = features.paginated_shipments?.is_enabled || false;
  const contacts = useSelector(selectContacts);
  const addresses = useSelector(selectAddresses);
  const shippingLines = useSelector(selectShippingLines);
  const billingItems = useSelector(selectBillingItems);
  const uoms = useSelector(selectUoms);
  const weightUoms = useSelector(selectWeightUoms);
  const products = useSelector(selectProducts);
  const shipmentTypes = useSelector(selectShipmentTypes);
  const isDeleting = useSelector(getIsDeleting);
  const customerId = shipmentDetails.data.customer_id;
  const billingTypes = useSelector((state: any) => selectBillingTypesByCustomer(state, customerId));
  const [selectedTrip, setSelectedTrip] = useState<TripListViewData | null>(null);
  const showDocsPanel = selectedTrip !== null;
  const ports = addresses.filter(
    (address) => address.data.port,
  );

  const portSelectOptions = createDropdownOptions(ports, 'samsara_name')
    .concat([{ value: 'dock-pickup', label: 'Off - Dock Pickup' }]);
  const addressSelectOptions = createDropdownOptions(addresses, 'samsara_name', 'samsara_formatted_address');
  const billingTypeSelectOptions = createDropdownOptions(billingTypes);
  const uomSelectOptions = createDropdownOptions(uoms);
  const weightUomSelectOptions = createDropdownOptions(weightUoms);
  const customerSelectOptions = createDropdownOptions(contacts);
  const shipmentTypeOptions = createDropdownOptions(shipmentTypes);
  const shippingLineOptions = createDropdownOptions(shippingLines);
  const userDropdownOptions = createDropdownOptions(Object.values(users));
  const shipmentInvoice = shipmentDetails.data?.invoice;
  const shipmentRoles = shipmentDetails.data.role_ids || shipmentDetails.roles || [];
  const fields = getFields(shipmentDetails, shipmentTypes);

  const handlePaginatedSave = async (ship: Shipment, reload: boolean) => {
    if (isPaginationEnabled) {
      await dispatch(saveShipmentDetailsPaginated(ship, reload));
    } else {
      await dispatch(saveShipmentDetails(ship, reload));
    }
  };
  const handleCancel = () => navigate(-1);

  const handleDuplicate = async (duplicateTrips: boolean) => {
    try {
      setIsDuplicating(true);
      await handlePaginatedSave(shipmentDetails, false);
      const response = await duplicateShipment(shipmentDetails.entity_id, duplicateTrips);
      if (response.status === 200) {
        const duplicated: Shipment = response.data.data;
        await handlePaginatedSave(duplicated, false);
        setIsDuplicating(false);
        setShowDuplicateModal(false);
        Analytics.duplicateShipment(orgCode, duplicateTrips);
        dispatch(resetShipments());
        navigate(`/${orgCode}/shipments/${duplicated.entity_id}`);
      }
    } catch (error) {
      if (error instanceof Error) {
        const errorMessage = `Couldn't duplicate shipment. ${error.message}.
        Please contact support if the problem persists.`;
        toast(errorMessage, { type: 'error' });
      }
    } finally {
      setIsDuplicating(false);
    }
  };

  const handleSave = async () => {
    const valid = isShipmentValid(shipmentDetails, fields);
    const errors = Object.values(valid).some((val) => val === false);
    if (errors) {
      setFormValidation(valid);
      const fieldData: any = fields;
      const errorFields = Object.keys(valid).reduce((message, key) => {
        if (valid[key] === false) {
          const name = fieldData[key]?.name || '';
          return message === '' ? name : `${message}, ${name}`
        }
        return message;
      }, '');
      toast(`Must fill out ${errorFields}`, { type: 'error' })
      return;
    }
    try {
      await handlePaginatedSave(shipmentDetails, false);
      const successMessage = 'Shipment was updated successfully.';
      toast(successMessage, { type: 'success' });
      Analytics.saveShipment(orgCode);
      navigate(-1);
    } catch (e: any) {
      const errorMessage = `Couldn't save shipment. ${e.message}. Please contact support if the problem persists.`;
      toast(errorMessage, );
    }
  };

  const handleCreateTemplate = async (name: string, _: string, boardId: string, groupId: string) => {
    try {
      setIsSavingTemplate(true);
      const params = {
        name,
        shipment_id: shipmentDetails.entity_id,
        board_ids: [boardId],
        group_ids: [groupId],
      };
      await handlePaginatedSave(shipmentDetails, true);
      const response = await createTemplate(params);
      if (response.status === 200) {
        await dispatch(getSettingByName(SETTINGS_NAMES.TRIP_TEMPLATES, false));
        await dispatch(getSettingByName(SETTINGS_NAMES.GROUPS, false));
        toast("Success! Template created.", { type: 'success' });
      }
    } catch (error) {
      toast("Couldn't create template", { type: 'error' });
      Analytics.capture(error);
    } finally {
      setIsSavingTemplate(false);
      setShowTemplateModal(false);
    }
  }
  
  const removeShipmentFromInvoice = async (shipmentId: string) => {
    try {
      await deleteInvoiceShipment(shipmentId);
      const successMessage = 'Invoice was updated successfully.';
      toast(successMessage, { type: 'success' });
      navigate(`/${orgCode}/invoices/${shipmentInvoice?.entity_id}`);
    } catch (e: any) {
      const errorMessage = `Couldn't remove shipment. ${e.description} Please contact support if the problem persists.`;
      toast(errorMessage, { type: 'error' });
    }
  };

  const handleOpenDocs = (trip: TripListViewData) => {
    setSelectedTrip(trip);
  };

  const handleUpdateRoles = (roleIds: string[]) => {
    const updates: Shipment = {
      ...shipmentDetails,
      data: {
        ...shipmentDetails.data,
        role_ids: roleIds,
      },
    };
    if (isPaginationEnabled) {
      dispatch(updateShipmentDetailsPaginated(updates));
    } else {
      dispatch(updateShipmentDetails(updates));
    }
  };

  const handleChange = (updates: any) => {
    if (isPaginationEnabled) {
      dispatch(updateShipmentDetailsPaginated(updates));
    } else {
      dispatch(updateShipmentDetails(updates));
    }
  };
  
  return (
    <div>
      <Row>
        <Col md={showDocsPanel ? 8 : 12}>
          <Stack
            direction="horizontal"
            className="mb-3"
            gap={3}
            onMouseEnter={() => setShowShipmentMenu(true)}
            onMouseLeave={() => setShowShipmentMenu(false)}
          >
            <h1 data-cy="shipment-title" className="h3">
              {getShipmentPageTitle(shipmentDetails.data.shipment_no)}
            </h1>
            {showShipmentMenu && (
              <Permission resources={WriteShipmentPermissions}>
                <Stack direction="horizontal" gap={3}>
                  <ShipmentMenu
                    onDelete={() => setShouldShowDeleteModal(true)}
                    onDuplicate={() => setShowDuplicateModal(true)}
                    onTemplate={() => setShowTemplateModal(true)}
                  />
                  <ShareButton roleIds={shipmentRoles} onUpdateRoles={handleUpdateRoles} />
                </Stack>
              </Permission>
            )}
          </Stack>
          <ShipmentForm
            shipmentDetails={shipmentDetails}
            billingTypes={billingTypes}
            billingItems={billingItems}
            products={products}
            shipmentTypeEntityOptions={shipmentTypeOptions}
            shippingLineOptions={shippingLineOptions}
            isEditable={isEditable}
            validation={formValidation}
            fields={fields}
            customerSelectOptions={customerSelectOptions}
            addressSelectOptions={addressSelectOptions}
            portSelectOptions={portSelectOptions}
            uomSelectOptions={uomSelectOptions}
            userSelectOptions={userDropdownOptions}
            weightUomSelectOptions={weightUomSelectOptions}
            onUpdate={handleChange}
          />
          <Permission resources={ReadTripPermissions}>
            <TripSummary
              shipmentDetails={shipmentDetails}
              isEditable={isEditable}
              showDocuments
              openDocuments={handleOpenDocs}
            />
          </Permission>
          <Documents shipmentDetails={shipmentDetails} />
          {shipmentInvoice && isInvoicesEnabled && (
            <Permission resources={ReadInvoicePermissions}>
              <InvoiceCard
                invoiceDetails={shipmentInvoice}
                setRemoveShipmentModal={setRemoveShipmentModal}
              />
            </Permission>
          )}
          {showTemplateModal && (
            <AddTripTemplateModal
              shouldShowModal={showTemplateModal}
              isSaving={isSavingTemplate}
              selectedShipmentId={shipmentDetails.entity_id}
              showShipment={false}
              saveItem={handleCreateTemplate}
              cancelSave={() => setShowTemplateModal(false)}
            />
          )}
          {isInvoicesEnabled && (
            <BillingCard
              disabled={!isEditable}
              shipmentDetails={shipmentDetails}
              billingTypeSelectOptions={billingTypeSelectOptions}
              validation={formValidation}
              fields={fields}
              billingTypes={billingTypes}
              billingItems={billingItems}
            />
          )}
          {isCostsEnabled && (
            <Permission resources={CostReadPermissions}>
              <ShipmentCostCard
                shipment={shipmentDetails}
              />
            </Permission>
          )}
          <PageFooterButtons
            disabled={isDeleting}
            page="shipmentDetails"
            deleteButton={false}
            handleSave={handleSave}
            handleClose={handleCancel}
            handleDelete={() => setShouldShowDeleteModal(true)}
            isEditable={isEditable}
          />
          {showDuplicateModal && (
            <Permission resources={WriteShipmentPermissions}>
              <DuplicateShipmentModal
                shouldShow={showDuplicateModal}
                isSaving={isDuplicating}
                onDuplicate={handleDuplicate}
                onCancel={() => setShowDuplicateModal(false)}
              />
            </Permission>
          )}
          {shouldShowDeleteModal && (
            <Permission resources={WriteShipmentPermissions}>
              <DeleteShipmentModal
                shipment={shipmentDetails}
                shouldShow={shouldShowDeleteModal}
                onCancel={() => setShouldShowDeleteModal(false)}
              />
            </Permission>
          )}
          <Permission resources={WriteInvoicePermissions}>
            <DeleteModal
              title="Remove shipment from Invoice?"
              description="Are you sure you want to remove this shipment?"
              shouldShowDeleteModal={removeShipmentModal}
              isDeleting={isDeleting}
              deleteItem={() => removeShipmentFromInvoice(shipmentDetails.entity_id)}
              cancelDeletion={() => setRemoveShipmentModal(false)}
              deleteBtnText="Remove"
            />
          </Permission>
        </Col>
        {showDocsPanel && selectedTrip ? (
          <Col md={4}>
            <DocumentsPanel
              shipment={shipmentDetails}
              trip={selectedTrip}
              onClose={() => setSelectedTrip(null)}
            />
          </Col>
        ) : null}
      </Row>
    </div>
  );
}
