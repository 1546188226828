import { useCallback, useEffect, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import { Form, FormLabel } from 'react-bootstrap';
import { createDropdownOptions, debounce } from '../../../utils/core.utils';
import Analytics from '../../../utils/analytics';
import { searchSettings } from '../../../redux/slices/settings';
import { DropdownOptionsModel } from '../SelectInput.models';

interface Props {
  disabled: boolean;
  labelText: string;
  name: string;
  page: string;
  value: string;
  options: DropdownOptionsModel[];
  emptyStateOptions: DropdownOptionsModel[];
  shouldHaveMargin?: boolean,
  isClearable?: boolean,
  settingName: string;
  dataField: string;
  dataCy: string;
  handleChange: (prop: string, val: string) => void;
}

function HopperSearchInput({
  disabled,
  labelText,
  name,
  page,
  value,
  options = [],
  emptyStateOptions = [],
  shouldHaveMargin = true,
  isClearable = true,
  settingName,
  dataField,
  handleChange,
  dataCy,
}: Props) {
  const selectOptions = value === '' && emptyStateOptions.length ? emptyStateOptions : options;
  const [dropdownOptions, setDropdownOptions] = useState(selectOptions);
  const [isSearching, setIsSearching] = useState(false);
  useEffect(() => {
    setDropdownOptions(selectOptions);
  }, [selectOptions]);

  const onInputChange = async (searchValue: string) => {
    if (!searchValue) {
      return false;
    }
    try {
      setIsSearching(true);
      const { data } = await searchSettings(
        settingName,
        searchValue,
        dataField,
      );
      const items = data?.data?.items || [];
      return setDropdownOptions(createDropdownOptions(items, dataField));
    } catch (e) {
      return Analytics.capture(e);
    } finally {
      setIsSearching(false);
    }
  };

  const optimizedSearch = useCallback(debounce(onInputChange), []);

  const onChange = (valueObj: SingleValue<DropdownOptionsModel>) => {
    setDropdownOptions([
      {
        label: valueObj?.label || '',
        value: valueObj?.value || '',
      }
    ]);
    handleChange(name, valueObj ? valueObj.value : '');
  };

  const selectValue = dropdownOptions.filter((option) => option.value === value);

  return (
    <Form.Group className={`${shouldHaveMargin ? 'mb-3' : ''}`} data-cy={dataCy}>
      {labelText ? (
        <FormLabel>
          <h5>
            {labelText}
          </h5>
        </FormLabel>
      ) : null}
      <Select
        id={`${page}-${name}__select`}
        instanceId={` ${page}-${name}`}
        inputId={`${page}-${name}__input`}
        isSearchable
        isDisabled={disabled}
        isLoading={isSearching}
        className={`react-select-container ${name}`}
        classNamePrefix="react-select"
        isClearable={isClearable}
        options={dropdownOptions}
        value={selectValue}
        placeholder="Type..."
        onChange={onChange}
        onInputChange={optimizedSearch}
      />
    </Form.Group>
  );
}

HopperSearchInput.defaultProps = {
  shouldHaveMargin: true,
  isClearable: true,
}

export default HopperSearchInput;