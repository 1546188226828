/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

// import useAppDispatch from '../../../../hooks/useAppDispatch';
import { SelectInput } from '../../../../components/shared/Input';
import CustomerForm from './ShipmentTypes/CustomerForm';
import ProductForm from './ShipmentTypes/ProductForm';
// import { updateShipmentDetails } from '../../../../redux/slices/shipments';
// import {
//   updateShipmentDetails as updateShipmentDetailsPaginated
// } from '../../../../redux/slices/shipment-list/shipment-list';
import { SHIPMENT_STATUS_TYPES } from '../../constants/shipments.constants';
import { Shipment, ShipmentTypeFieldData } from '../../../../redux/models/shipment.models';
import { BillingItem, BillingType } from '../../../../redux/models/billing.models';
import ShippingTypeForm from './ShipmentTypes/ShippingTypeForm';
import { EntityContainer } from '../../../../redux/models/core.models';
import { DropdownOptionsModel } from '../../../../components/shared/SelectInput.models';
// import useFeatureFlags from '../../../../hooks/useFeatureFlags';
import { handleShipmentFormData } from '../../utils/shipments.utils';
import Permission from '../../../../components/shared/permissions/Permission';
import { FeatureResource, ResourcePermission } from '../../../../redux/models/feature.flags.models';
import SpecialReqsForm from './ShipmentTypes/SpecialReqsForm';

const SHIPMENT_FORM_ID = 'shipmentDetails';
const UserReadPermissions = {
  [FeatureResource.User]: ResourcePermission.Read,
};

interface Props {
  shipmentDetails: Shipment;
  fields: ShipmentTypeFieldData;
  billingTypes: BillingType[];
  billingItems: BillingItem[];
  validation: EntityContainer<boolean>;
  shipmentTypeEntityOptions: DropdownOptionsModel[];
  customerSelectOptions: DropdownOptionsModel[];
  addressSelectOptions: DropdownOptionsModel[];
  shippingLineOptions: DropdownOptionsModel[];
  isEditable: any;
  portSelectOptions: DropdownOptionsModel[];
  products: DropdownOptionsModel[];
  uomSelectOptions: DropdownOptionsModel[];
  userSelectOptions: DropdownOptionsModel[];
  weightUomSelectOptions: DropdownOptionsModel[];
  onUpdate: (shipment: any) => void;
}

export default function ShipmentForm({
  shipmentDetails,
  billingTypes,
  billingItems,
  fields,
  customerSelectOptions,
  addressSelectOptions,
  shipmentTypeEntityOptions,
  isEditable,
  portSelectOptions,
  products,
  uomSelectOptions,
  userSelectOptions,
  shippingLineOptions,
  weightUomSelectOptions,
  validation,
  onUpdate,
}: Props) {
  // const dispatch = useAppDispatch();
  // const features = useFeatureFlags();
  // const isPaginationEnabled = features.paginated_shipments?.is_enabled || false;
  const statusTypeOptions = Object.values(SHIPMENT_STATUS_TYPES);

  const handleForm = (prop: string, value: string) => {
    const updates = handleShipmentFormData(
      prop,
      value,
      shipmentDetails,
      billingTypes,
      billingItems,
    );
    // if (isPaginationEnabled) {
    //   dispatch(updateShipmentDetailsPaginated(updates));
    // } else {
    //   dispatch(updateShipmentDetails(updates));
    // }
    onUpdate(updates);
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Row className="mt-2">
            <Col xs={4}>
              <SelectInput
                value={shipmentDetails.data.status || ''}
                labelText="Status"
                name="status"
                page={SHIPMENT_FORM_ID}
                options={statusTypeOptions as any}
                handleChange={handleForm}
                dataCy="shipment_status"
                disabled={!isEditable}
              />
            </Col>
            <Col xs={4}>
              <SelectInput
                value={shipmentDetails.data.type_id}
                labelText="Shipment Type"
                name="type_id"
                page={SHIPMENT_FORM_ID}
                options={shipmentTypeEntityOptions as any}
                handleChange={handleForm}
                dataCy="shipment_type_id"
                disabled={!isEditable}
              />
            </Col>
            <Permission resources={UserReadPermissions}>
              <Col xs={4}>
                <SelectInput
                  value={shipmentDetails.data.assignee_id || ''}
                  labelText="Assignee"
                  name="assignee_id"
                  page={SHIPMENT_FORM_ID}
                  options={userSelectOptions as any}
                  handleChange={handleForm}
                  dataCy="assignee_id"
                  disabled={!isEditable}
                />
              </Col>
            </Permission>
          </Row>
          {isEditable && (
            <CustomerForm
              shipmentDetails={shipmentDetails}
              customerSelectOptions={customerSelectOptions}
              page={SHIPMENT_FORM_ID}
              disabled={!isEditable}
              validation={validation}
              fields={fields}
              handleChange={handleForm}
              // onCreateCustomer={onCreateCustomer}
            />
          )}
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>   
          <ProductForm
            shipmentDetails={shipmentDetails}
            uomSelectOptions={uomSelectOptions}
            weightUomSelectOptions={weightUomSelectOptions}
            page={SHIPMENT_FORM_ID}
            handleChange={handleForm}
            products={products}
            disabled={!isEditable}
            validation={validation}
            fields={fields}
          />
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <ShippingTypeForm
            shipmentDetails={shipmentDetails}
            addressSelectOptions={addressSelectOptions}
            shippingLineOptions={shippingLineOptions}
            portSelectOptions={portSelectOptions}
            disabled={!isEditable}
            validation={validation}
            fields={fields}
            handleChange={handleForm}
          />
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <SpecialReqsForm
            shipmentDetails={shipmentDetails}
            page={SHIPMENT_FORM_ID}
            disabled={!isEditable}
            handleChange={handleForm}
          />
        </Card.Body>
      </Card>
    </>
  );
}
