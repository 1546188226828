/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';

import { DateService } from '../../../utils/dateService';

import { getDefaultTrip } from '../../../pages/boards/constants/boards.constants';
import {
  saveFilterValueToSessionStorage,
} from '../../../utils/core.utils';
import {
  initialState,
  resetInitialState,
  FILTER_KEY,
  Shipment,
  ShipmentListFilters,
  ShipmentData,
  ShipmentListState,
  getSessionFilters,
} from './shipment-list.models';
import {
  formatShipments, groupBy, getError, getFindShipmentFilters, shouldAddShipmentToState
} from './shipment-list.utils';
import { EntityContainer } from '../../models/core.models';
import Analytics from '../../../utils/analytics';
import { ShipmentServiceApiProvider } from '../../../services/ShipmentServiceProvider';
import { TripServiceApiProvider } from '../../../services/TripServiceProvider';
import { formatModalShipments } from '../../../pages/shipments/ShipmentsTable/utils/shipmentTable.utils';
import { TripData } from '../../models/trip.models';
import { Api } from '../../../services/services';
import { ApiQuery, DEFUALT_CREATED_AT_SORTING } from '../../models/network.models';
import { Document } from '../../models/file.models';
import { SHIPMENT_LIST } from '../slices';

const api = Api.Shipments;

const SLICE_NAME = SHIPMENT_LIST;

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setShipments(state, action) {
      const { shipments, filters, totalShipments } = action.payload;
      const groups = groupBy(filters.grouping, shipments);
      const storedFilters = JSON.stringify(filters);
      const ids = shipments.map((shipment: Shipment) => shipment.entity_id);
      const container = shipments.reduce((store: any, shipment: Shipment) => {
        return {
          ...store,
          [shipment.entity_id]: shipment,
        };
      }, {});
      saveFilterValueToSessionStorage(FILTER_KEY, storedFilters);
      state.groupedShipments = groups;
      state.container = container;
      state.list = ids;
      state.totalShipments = totalShipments || 0;
      state.filterParams = {
        ...filters,
        page: 0,
      };
    },
    setShipmentsNextPage(state, action) {
      const {
        shipments,
        // groups,
        filters,
        nextPage,
        totalShipments,
      } = action.payload;
      // const groups = groupBy(filters.grouping, shipments);
      // const storedFilters = JSON.stringify(filters);
      // saveFilterValueToSessionStorage(FILTER_KEY, storedFilters);
      // state.groupedShipments = groups;
      const ids = shipments.map((shipment: Shipment) => shipment.entity_id);
      const container = shipments.reduce((store: any, shipment: Shipment) => {
        return {
          ...store,
          [shipment.entity_id]: shipment,
        };
      }, {
        ...state.container
      });
      const groups = groupBy(filters.grouping, Object.values(container));
      state.groupedShipments = groups;
      state.totalShipments = totalShipments || 0;
      state.container = {
        ...container,
      };
      state.list = [
        ...state.list,
        ...ids,
      ];
      state.filterParams = {
        ...state.filterParams,
        page: nextPage || 0,
      };
    },
    setAreShipmentsLoading(state, action) {
      state.areShipmentsLoading = action.payload;
    },
    setAreShipmentsLoadingMore(state, action) {
      state.areShipmentsLoadingMore = action.payload;
    },
    setIsLoaded(state, action) {
      state.isLoaded = action.payload;
    },
    setShipmentDetails(state, action) {
      const shipment: Shipment = action.payload.data;
      const listView = state.container[shipment.entity_id];
      const dates = listView?.data.dates || {
        start_date: '',
        end_date: ''
      };
      const updated: Shipment = {
        ...shipment,
        data: {
          ...shipment.data,
          dates,
        },
      };
      const ids = state.list || [];
      const addToState = shouldAddShipmentToState(
        shipment.entity_id, ids, state.filterParams.page,
        state.filterParams.pageSize,
        state.totalShipments
      );
      if (addToState) {
        state.list = [
          ...ids,
          shipment.entity_id,
        ];
      }
      state.shipmentDetails = updated;
      state.container[shipment.entity_id] = updated;
    },
    updateShipmentDetails(state, action) {
      state.shipmentDetails = action.payload;
    },
    setAreShipmentDetailsLoading(state, action) {
      state.areShipmentDetailsLoading = action.payload;
    },
    setFilterParams(state, action) {
      const storedFilters = JSON.stringify(action.payload);
      saveFilterValueToSessionStorage(FILTER_KEY, storedFilters);
      state.filterParams = action.payload;
    },
    setGroupBy(state, action) {
      const filters = {
        ...state.filterParams,
        grouping: action.payload,
      };
      const storedFilters = JSON.stringify(filters);
      saveFilterValueToSessionStorage(FILTER_KEY, storedFilters);
      const shipments: Shipment[] = Object.values({ ...state.container });
      const groups = groupBy(action.payload, shipments);
      state.groupedShipments = groups;
      state.filterParams = filters;
    },
    setPageSize(state, action) {
      const filters = {
        ...state.filterParams,
        pageSize: action.payload,
      };
      const storedFilters = JSON.stringify(filters);
      saveFilterValueToSessionStorage(FILTER_KEY, storedFilters);
      const shipments: Shipment[] = Object.values({ ...state.container });
      const groups = groupBy(action.payload, shipments);
      state.groupedShipments = groups;
      state.filterParams = filters;
    },
    resetShipments: () => resetInitialState(),
  },
});

export const {
  setShipments,
  setShipmentsNextPage,
  setAreShipmentsLoading,
  setAreShipmentsLoadingMore,
  setIsLoaded,
  setShipmentDetails,
  updateShipmentDetails,
  setAreShipmentDetailsLoading,
  setFilterParams,
  setGroupBy,
  setPageSize,
  resetShipments,
} = slice.actions;

const formatShipmentPostBody = (shipment: Shipment, tripIds: string[] = []) => {
  const trips = shipment.data.trips || [];
  const trIds = trips.filter((tr) => tr.entity_id !== undefined).map((trip) => trip.entity_id || '').concat(tripIds);
  const shipmentData: Shipment = {
    ...shipment,
    data: {
      ...shipment.data,
      trip_ids: trIds || [],
    },
  };
  delete shipmentData.data.trips;
  return shipmentData;
};

export const loadUnassignedShipments = async (listFilters: ShipmentListFilters, page: number, pageSize: 10) => {
  const queryfilters = getFindShipmentFilters(listFilters);
  const params: ApiQuery = {
    filters: [
      {
        field: {
          name: 'data.trip_ids',
          type: 'rel',
        },
        op: 'eq',
        value: [],
      },
      ...queryfilters,
    ],
    operator: "AND",
    pagination: {
      page,
      page_size: pageSize,
    },
    sorting: DEFUALT_CREATED_AT_SORTING,
  };
  return Api.Shipments.find(params);
}

export const duplicateShipment = (shipmentId: string, trips: boolean) => {
  return Api.Shipments.duplicate(shipmentId, trips);
}

export const loadShipments = (shipmentSettings: any) => async (dispatch: any) => {
  dispatch(setAreShipmentsLoading(true));
  try {
    const { addresses, contacts, billingTypes } = shipmentSettings;
    const filters = getSessionFilters();
    
    const { data } = await ShipmentServiceApiProvider.getShipments(filters);
    const shipments = formatShipments(data.data, contacts, billingTypes, addresses);
    const modalShipments = formatModalShipments(data.data, contacts, billingTypes, addresses);
    const foundModalShipments = formatModalShipments(data.data, contacts, billingTypes, addresses);
    return dispatch(
      setShipments({
        shipments: [...shipments],
        filters,
        modalShipments: [...modalShipments, ...foundModalShipments]
      })
    );
  } catch (e) {
    return getError(e);
  } finally {
    dispatch(setAreShipmentsLoading(false));
  }
};

export const fetchShipmentsPaginated = (filters: ShipmentListFilters) => {
  return Api.Shipments.listPaginated(filters, filters.page, filters.pageSize);
};

export const loadShipmentsPaginated = (
  page: number,
  pageSize: number,
  filters: ShipmentListFilters,
) => async (dispatch: any) => {
  dispatch(setAreShipmentsLoading(true));
  try {
    const { data } = await Api.Shipments.listPaginated(filters, page || 0, pageSize);
    const items = data.data.items || [];
    const total = data.data.total_count || 0;
    return dispatch(
      setShipments({
        shipments: items,
        filters,
        totalShipments: total,
      })
    );
  } catch (e) {
    return getError(e);
  } finally {
    dispatch(setAreShipmentsLoading(false));
    dispatch(setIsLoaded(true));
  }
};


export const loadShipmentsNextPage = (
  page: number,
  pageSize: number,
) => async (dispatch: any) => {
  try {
    dispatch(setAreShipmentsLoadingMore(true));
    const filters = getSessionFilters();
    const { data } = await Api.Shipments.listPaginated(filters, page, pageSize);
    const items = data.data.items || [];
    const total = data.data.total_count || 0;
    dispatch(
      setShipmentsNextPage({
        shipments: items,
        nextPage: page,
        filters,
        totalShipments: total,
      })
    );
  } catch (e) {
    getError(e);
  } finally {
    dispatch(setAreShipmentsLoadingMore(false));
  }
};

export const getShipments = (filters: ShipmentListFilters, shipmentSettings: any) => async (dispatch: any) => {
  dispatch(setAreShipmentsLoading(true));
  try {
    const { addresses, contacts, billingTypes } = shipmentSettings;
    const { data } = await ShipmentServiceApiProvider.getShipments(filters);
    const shipments = formatShipments(data.data, contacts, billingTypes, addresses);
    const modalShipments = formatModalShipments(data.data, contacts, billingTypes, addresses);
    return dispatch(setShipments({ shipments, filters, modalShipments }));
  } catch (e) {
    return getError(e);
  } finally {
    dispatch(setAreShipmentsLoading(false));
  }
};

export const updateShipment = (shipment: Shipment) => {
  return Api.Shipments.update(shipment);
};

export const addShareDocument = (shipmentId: string, document: Document) => {
  return Api.Shipments.addDocument(shipmentId, document);
};

export const getShipmentDetails = (shipmentId: string) => async (dispatch: any) => {
  dispatch(setAreShipmentDetailsLoading(true));
  try {
    const { data: shipment } = await ShipmentServiceApiProvider.getShipmentDetails(shipmentId);
    return dispatch(setShipmentDetails(shipment));
  } catch (e) {
    return getError(e);
  } finally {
    dispatch(setAreShipmentDetailsLoading(false));
  }
};

export const loadShipmentDetails = (shipmentId: string) => {
  return ShipmentServiceApiProvider.getShipmentDetails(shipmentId);
};

/**
 *  - Create default trip
 *  - Update shipment
 *  - Get shipment details
 *  - Dispatch shipment
 */
export const addShipmentTrip = (shipment: Shipment, orgCode: string | undefined) => async (dispatch: any) => {
  try {
    const date = DateService.getISOString();
    const tripData = getDefaultTrip(date, '', '', '', [shipment.entity_id]);
    const { data: trip } = await TripServiceApiProvider.createTrip(tripData);
    const tripId: string = trip.data.entity_id;
    const shipmentPostBody = formatShipmentPostBody(shipment, [tripId]);
    await ShipmentServiceApiProvider.updateShipment(shipmentPostBody);
    const { data: updatedDetails } = await ShipmentServiceApiProvider.getShipmentDetails(shipment.entity_id);
    return dispatch(setShipmentDetails(updatedDetails));
  } catch (e) {
    return getError(e, "Couldn't add shipment trip.");
  } finally {
    Analytics.createNewTrip(orgCode);
  }
};

export const createBoardShipment = async (body: ShipmentData, orgCode: string | undefined) => {
  try {
    const { data: shipment } = await ShipmentServiceApiProvider.createBoardShipment(body);
    return shipment.data;
  } catch (e) {
    return getError(e, "Couldn't create shipment", "create");
  } finally {
    Analytics.createShipment(orgCode);
  }
};

export const createBoardTrip = async (data: TripData, orgCode: string | undefined) => {
  try {
    const { data: trip } = await Api.Trips.create(data);
    return trip.data;
  } catch (e) {
    return getError(e, "Couldn't create trip", "create");
  } finally {
    Analytics.createNewTrip(orgCode);
  }
};

export const saveShipmentDetails = (data: Shipment, reload = true) => async (dispatch: any) => {
  dispatch(setAreShipmentDetailsLoading(true));
  try {
    const shipmentData = {
      ...data.data,
    };
    const trips = shipmentData.trips || [];
    shipmentData.trip_ids = trips.map((trip) => trip.entity_id || '');
    const postBody = {
      ...data,
      data: {
        ...shipmentData,
      },
    };
    const { data: updatedShipment } = await ShipmentServiceApiProvider.updateShipment(postBody);
    if (reload) {
      const { data: reloadedShipment } = await ShipmentServiceApiProvider.getShipmentDetails(data.entity_id);
      return dispatch(setShipmentDetails(reloadedShipment));
    }
    return dispatch(setShipmentDetails(updatedShipment));
  } catch (e) {
    return getError(e, "Couldn't save shipment", "save");
  } finally {
    dispatch(setAreShipmentDetailsLoading(false));
  }
};

export const createShipment = async () => {
  try {
    const { data: shipment } = await ShipmentServiceApiProvider.createShipment();
    return shipment.data;
  } catch (e) {
    return getError(e, "Couldn't create shipment", "create");
  }
};

export const deleteShipment = async (shipment: Shipment, trips: boolean) => api.deleteShipment(shipment, trips);

export const selectShipmentListView = (state: EntityContainer<ShipmentListState>) => state[SLICE_NAME].list || [];
export const selectIsShipmentsLoaded = (state: EntityContainer<ShipmentListState>) => state[SLICE_NAME].isLoaded;
export const selectGroupedShipments = (state: EntityContainer<ShipmentListState>) => state[SLICE_NAME].groupedShipments;
export const selectTotalShipments = (state: EntityContainer<ShipmentListState>) => state[SLICE_NAME].totalShipments;
export const selectShipmentPage = (state: EntityContainer<ShipmentListState>) => {
  return {
    page: state[SLICE_NAME].filterParams.page,
    pageSize: state[SLICE_NAME].filterParams.pageSize,
  };
};
export const selectAreShipmentsLoading = (state: EntityContainer<ShipmentListState>) => (
  state[SLICE_NAME].areShipmentsLoading
);
export const selectAreShipmentsLoadingMore = (state: EntityContainer<ShipmentListState>) => (
  state[SLICE_NAME].areShipmentsLoadingMore
);


export const selectShipmentListViewItem = (state: EntityContainer<ShipmentListState>, id: string) => {
  return state[SLICE_NAME].container[id];
};
export const selectShipmentDetails = (state: EntityContainer<ShipmentListState>) => state[SLICE_NAME].shipmentDetails;
export const selectAreShipmentDetailsLoading = (state: EntityContainer<ShipmentListState>) => (
  state[SLICE_NAME].areShipmentDetailsLoading
);
export const selectShipmentFilterParams = (state: EntityContainer<ShipmentListState>) => state[SLICE_NAME].filterParams;

export const shipmentListReducer = slice.reducer;
