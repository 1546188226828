/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { formatTrips } from '../../../pages/boards/TripTable/utils/tripTable.utils';
import { getDefaultShipment } from '../../../pages/boards/constants/boards.constants';
import {
  initialState,
  createTripsState,
  updateTripsPusherEvent,
  removeTripPusherEvent,
} from '../trips.models';
import { TripServiceApiProvider } from '../../../services/TripServiceProvider';
import { ShipmentServiceApiProvider } from '../../../services/ShipmentServiceProvider';
import Analytics from '../../../utils/analytics';
import { Api } from '../../../services/services';
import { DispatchNotificationParams, Move, Trip, TripBoardModel, TripDetails } from '../../models/trip.models';
import { SearchDateRange } from '../../../components/modals/SearchFilter/searchFilter.models';
import { BoardSettings } from '../../models/board.models';
import { AppState, DispatchProps } from '../../models/state.models';
import { TimeDistanceEstimate, getError, formatTripDates } from './trips.utils';
import { Shipment } from '../../models/shipment.models';
import { TRIPS } from '../slices';
import { ApiQuery } from '../../models/network.models';
import ApiResponse from '../../../services/base/ApiResponse';
import { EntityContainer } from '../../models/core.models';
import { Address } from '../../models/settings.models';
import { DispatchRouteAsyncProps } from '../../../services/base/TripServiceProvider.models';

const SLICE_NAME = TRIPS;

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setTrips(state, action) {
      const { trips, view, shipments } = createTripsState(action.payload);
      state.trips = trips;
      state.view = view;
      state.shipments = shipments;
    },
    setAreTripsLoading(state, action) {
      state.areTripsLoading = action.payload;
    },
    setTripDetails(state, action) {
      state.tripDetails = action.payload.data;
    },
    setTripRoute(state, action) {
      state.tripRoutes.push(action.payload.data);
    },
    setTripRouteEstimates(state, action) {
      const { duration, distance } = action.payload;
      const td = state.tripDetails;
      if (td) {
        const data: TripDetails = {
          ...td,
          data: {
            ...td.data,
            estimated_distance_metres: distance,
            estimated_duration_seconds: duration,
          },
        };
        state.tripDetails = data;
        state.isFormDirty = true;
      }
    },
    updateTripDetails(state, action) {
      state.tripDetails = action.payload;
      state.isFormDirty = true;
    },
    updateTripDetailsMoves(state, action) {
      const td = state.tripDetails;
      if (td) {
        const data = {
          ...td,
          data: {
            ...td.data,
            moves: action.payload || [],
          },
        };
        state.tripDetails = data;
        state.isFormDirty = true;
      }
    },
    updateTripDetailsShipments(state, action) {
      const td = state.tripDetails;
      if (td) {
        const data = {
          ...td,
          data: {
            ...td.data,
            shipments: action.payload || [],
          },
        };
        state.tripDetails = data;
      }
      // state.tripDetails.data.shipments = action.payload;
    },
    setAreTripDetailsLoading(state, action) {
      state.areTripDetailsLoading = action.payload;
    },
    setOriginalDriverId(state, action) {
      state.originalDriverId = action.payload || '';
    },
    setAddMove(state, action) {
      const td = state.tripDetails;
      const moves = state.tripDetails?.data.moves || [];
      moves.push(action.payload);
      if (td) {
        const data = {
          ...td,
          data: {
            ...td.data,
            moves,
          },
        };
        state.tripDetails = data;
      }
      // state.tripDetails.data.moves = moves;
    },
    setIsTripRouteLoading(state, action) {
      state.isTripRouteLoading = action.payload;
    },
    setFilterParams(state, action) {
      state.filterParams = action.payload;
    },
    setSelectedTab(state, action) {
      state.selectedTab = action.payload;
    },
    setTripListDriver(state, action) {
      const tripId = action.payload.id || '';
      const updates = {
        ...state.trips,
        [tripId]: action.payload,
      };
      const { trips, view, shipments } = createTripsState(Object.values(updates));
      state.trips = trips;
      state.view = view;
      state.shipments = shipments;
    },
    setTripListDriverOptimised(state, action) {
      const optimised: TripBoardModel[] = action.payload || [];
      const data = optimised.reduce((store, tr) => {
        return {
          ...store,
          [tr.id]: tr,
        }
      }, state.trips);
      const { trips, view, shipments } = createTripsState(Object.values(data));
      state.trips = trips;
      state.view = view;
      state.shipments = shipments;
    },
    updateTripsFromPusher(state, action) {
      const { trip, boardSettings } = action.payload;
      const updatedTrips = updateTripsPusherEvent(state, trip, boardSettings);
      const { trips, view, shipments } = createTripsState(updatedTrips);
      state.trips = trips;
      state.view = view;
      state.shipments = shipments;
    },
    removeTripFromPusher(state, action) {
      const updatedTrips = removeTripPusherEvent(state, action.payload);
      const { trips, view, shipments } = createTripsState(updatedTrips);
      state.trips = trips;
      state.view = view;
      state.shipments = shipments;
    },
    setPlannerState(state, action) {
      state.plannerState = action.payload;
    },
    setPlannerTags(state, action) {
      state.plannerState.tags = action.payload;
    },
    resetTrips: (state) => {
      state = {
        ...initialState,
        filterParams: state.filterParams,
        selectedTab: state.selectedTab,
        plannerState: state.plannerState,
      }
    },
  },
});

export const {
  resetTrips,
  removeTripFromPusher,
  setAddMove,
  setAreTripsLoading,
  setAreTripDetailsLoading,
  setFilterParams,
  setOriginalDriverId,
  setIsTripRouteLoading,
  setPlannerState,
  setPlannerTags,
  setSelectedTab,
  setTrips,
  setTripDetails,
  setTripRoute,
  setTripListDriver,
  setTripListDriverOptimised,
  updateTripDetails,
  updateTripDetailsMoves,
  updateTripDetailsShipments,
  updateTripsFromPusher,
  setTripRouteEstimates,
} = slice.actions;

export const loadSharedTrip = (id: string, token: string, orgCode: string) => {
  return Api.Trips.getSharedTrip(id, token, orgCode);
};

export const confirmSharedTrip = (id: string, token: string, orgCode: string) => {
  return Api.Trips.confirmSharedTrip(id, token, orgCode);
};

export const completeSharedTrip = (id: string, token: string, orgCode: string) => {
  return Api.Trips.completeSharedTrip(id, token, orgCode);
};

export const getTripListViewById = (id: string) => {
  return Api.Trips.getListViewById(id);
};

export const fetchTrips = (boardId: string, filters: SearchDateRange) => {
  return TripServiceApiProvider.getTrips(boardId, filters);
};

export const getTrips = (
  boardId: string, filters: SearchDateRange, boardSettings: BoardSettings
) => async (dispatch: DispatchProps) => {
  dispatch(setAreTripsLoading(true));
  try {
    const { data } = await TripServiceApiProvider.getTrips(boardId, filters);
    const formatted = formatTrips({ trips: data.data, boardSettings });
    dispatch(setTrips(formatted));
  } catch (e) {
    getError(e);
  } finally {
    dispatch(setAreTripsLoading(false));
  }
};

export const findTrips = (query: ApiQuery) => {
  return TripServiceApiProvider.findTrips(query);
};

export const findMoves = (query: ApiQuery) => {
  return TripServiceApiProvider.findMoves(query);
};

export const deleteApiMove = (move: Move) => {
  return Api.Moves.delete(move);
}

export const getTripDetails = (tripId: string) => async (dispatch: DispatchProps) => {
  dispatch(setAreTripDetailsLoading(true));
  try {
    const { data: trip } = await TripServiceApiProvider.getTripDetails(tripId);
    if (!trip.data.data.status) {
      trip.data.data.status = 'select-status';
    }
    const driverId = trip.data.data.driver_id || '';
    setOriginalDriverId(driverId);
    dispatch(setTripDetails(trip));
  } catch (e) {
    getError(e);
  } finally {
    dispatch(setAreTripDetailsLoading(false));
  }
};

export const getFleetLocations = async (destinationId: string) => {
  try {
    const { data: locations } = await TripServiceApiProvider.getFleetLocations(destinationId);
    return locations.data;
  } catch (e) {
    const errorMessage = 'Couldn\'t get trailer locations. Please contact support if the problem persists.';
    throw new Error(errorMessage);
  }
};

export const dispatchTrip = async (tripId: string) => {
  try {
    const response = await TripServiceApiProvider.dispatchTrip(tripId);
    return response;
  } catch (e) {
    const errorMessage = 'Couldn\'t dispatch driver trip. Please contact support if the problem persists.';
    throw new Error(errorMessage);
  }
};

export const getTripDistance = async (moves: Move[], addresses: EntityContainer<Address>) => {
  const unassigned = moves.some((move) => move.data.destination_id === '');
  const length = moves.length || 0;
  const addrs = moves.map((move) => {
    const addressId = move.data.destination_id || '';
    return addresses[addressId];
  }).filter((address) => address);
  const nonSamsara = addrs.some((addr) => {
    const id = addr.data.samsara_id || '';
    return id.length < 2;
  });
  if (nonSamsara || unassigned || length < 2 || addrs.length !== length) {
    const est: TimeDistanceEstimate = {
      distance: 0,
      duration: 0,
    };
    return est;
  }
  try {
    const response = await Api.Addresses.estimateRoute(addrs);
    if (response.status === 200) {
      const estimate: TimeDistanceEstimate = response.data.data;
      return estimate
    } else {
      throw new Error('Error creating route estimate');
    }
  } catch (error) {
    Analytics.capture(error);
    throw error;
  }
};

export const updateTripDistance = (moves: Move[], addresses: EntityContainer<Address>) => async (dispatch: DispatchProps) => {
  const estimate = await getTripDistance(moves, addresses);
  dispatch(setTripRouteEstimates(estimate));
};

/**
 * 1. Save all moves - there's no post body so server needs the latest move data
 * 2. Send trip dispatch to server
 * @param {String} tripId entity_id of the trip
 * @returns
 */
export const sendTripRoute = (tripId: string) => async (dispatch: DispatchProps) => {
  dispatch(setIsTripRouteLoading(true));
  try {
    const { data: trip } = await TripServiceApiProvider.sendTripRoute(tripId);
    dispatch(setTripDetails(trip));
  } catch (e) {
    getError(e);
  } finally {
    dispatch(setIsTripRouteLoading(false));
  }
};

export const sendTripRouteAsync = (body: DispatchRouteAsyncProps) => {
  return TripServiceApiProvider.sendTripRouteAsync(body);
};

export const emailTrip = (tripId: string, data: DispatchNotificationParams) => {
  return Api.Trips.dispatchEmail(tripId, data);
};

/**
 * Sends trip details to driver
 * @param {String} routeId trip.data.samsara_route_id
 * @returns
 */
export const getTripRoute = (routeId: string) => async (dispatch: DispatchProps) => {
  dispatch(setIsTripRouteLoading(true));
  try {
    const { data: route } = await TripServiceApiProvider.getTripRoute(routeId);
    dispatch(setTripRoute(route));
  } catch (e) {
    getError(e, 'Error loading route info');
  } finally {
    dispatch(setIsTripRouteLoading(false));
  }
};

export const getTripDocument = async (documentId: string) => {
  try {
    const { data: document } = await TripServiceApiProvider.getTripDocument(documentId);
    return document;
  } catch (e) {
    getError(e);
    throw e;
  }
};

export const createTrip = async (body: object) => {
  try {
    const { data: trip } = await TripServiceApiProvider.createTrip(body);
    return trip.data;
  } catch (e) {
    return getError(e);
  }
};

/**
 * 1. Create shipment with trip_id
 * 2. Update trip with shipment entity_id
 * 3. Dispatch tripDetails
 */
export const createTripShipment = async (tripDetails: TripDetails) => {
  const shipmentPostBody: any = getDefaultShipment([tripDetails.entity_id]);
  const { data: shipment } = await ShipmentServiceApiProvider.createShipment(shipmentPostBody);
  const shipments = [...tripDetails.data.shipments, shipment.data];
  const updatedTrip = {
    ...tripDetails,
    data: {
      ...tripDetails.data,
      shipments,
    },
  };
  const { data: trip } = await TripServiceApiProvider.updateTrip(updatedTrip);
  return trip;
};

/**
 *
 * @param {Object} tripDetails Trip details object with entity_id and version no.
 * @param {Array} shipments Array of shipment details objects
 * @returns
 */
export const updateTripShipments = (
  tripDetails: TripDetails, shipments: Shipment[]
) => async (dispatch: DispatchProps) => {
  const addedShipments = shipments.map((shipment) => {
    const relatedTrips = shipment.data.trips || [];
    return {
      ...shipment,
      data: {
        ...shipment.data,
        trip_ids: relatedTrips.map((trip) => trip.entity_id),
      },
    };
  });
  try {
    const { data: updatedTrip } = await TripServiceApiProvider.addShipmentsToTrip(tripDetails.entity_id, {
      ...tripDetails,
      data: {
        ...tripDetails.data,
        shipments: addedShipments,
      },
    });
    return dispatch(setTripDetails(updatedTrip));
  } catch (e) {
    const errorMessage = 'Couldn\'t add shipments. Please contact support if the problem persists.';
    Analytics.capture(e);
    return toast(errorMessage, { type: 'error' });
  } finally {
    console.log('updating rels done');
  }
};

export const updateTripListDriver = (driverId: string, trip: TripBoardModel) => {
  return TripServiceApiProvider.updateDriver(driverId, trip.id, trip.version || '');
};

export const getTrip = (tripId: string) => {
  return Api.Trips.getById(tripId);
};

export const getTripHistory = (tripId: string) => {
  return Api.Trips.getActivityLog(tripId);
};

export const updateTrip = (trip: any) => {
  const updates = formatTripDates(trip);
  return Api.Trips.update(updates);
};

export const duplicateTrip = (tripId: string, shipments: boolean) => {
  return Api.Trips.duplicate(tripId, shipments);
}

export const createTripFromShipments = async (shipmentIds: string[]) => {
  try {
    const detailsResponse = await Api.Shipments.createTrip(shipmentIds);
    if (detailsResponse.status === 200) {
      const tripDetails: TripDetails = ApiResponse.parseItem(detailsResponse);
      return tripDetails;
    } else {
      throw new Error('Error creating trip');
    }
  } catch (error) {
    throw error;
  }
}

export const saveTripDetails = async (tripDetails: Trip) => {
  const updates = formatTripDates(tripDetails);
  return TripServiceApiProvider.updateTrip(updates);
}

export const deleteTrip = (trip: TripDetails, deleteShipments: boolean) => {
  const shipmentIds = trip.data.shipments.map((shipment) => shipment.entity_id);
  return Api.Trips.deleteTrip(trip, deleteShipments, shipmentIds);
}

export const addMove = (move: Move) => async (dispatch: DispatchProps) => dispatch(setAddMove(move));

export const selectAreTripsLoading = (state: AppState) => state[SLICE_NAME].areTripsLoading;
export const selectOriginalDriverId = (state: AppState) => state[SLICE_NAME].originalDriverId;
export const selectTrips = (state: AppState) => state[SLICE_NAME].trips;
export const selectTripViews = (state: AppState) => state[SLICE_NAME].view || {};
export const selectBoardShipments = (state: AppState) => state[SLICE_NAME].shipments || [];
export const selectTripsByCardId = (state: AppState, cardId: string) => state[SLICE_NAME].view[cardId] || [];
export const selectAreTripDetailsLoading = (state: AppState) => state[SLICE_NAME].areTripDetailsLoading;
export const selectIsTripDirty = (state: AppState) => state[SLICE_NAME].isFormDirty;
export const selectTripDetails = (state: AppState) => state[SLICE_NAME].tripDetails;
export const selectTripsFilterParams = (state: AppState) => state[SLICE_NAME].filterParams;
export const selectPlannerState = (state: AppState) => state[SLICE_NAME].plannerState;
export const selectIsAddingMove = (state: AppState) => state[SLICE_NAME].isAddingMove;
export const selectIsTripRouteLoading = (state: AppState) => state[SLICE_NAME].isTripRouteLoading;
export const selectDispatchTab = (state: AppState) => state[SLICE_NAME].selectedTab;
export const selectRoutesByTripNo = (state: AppState, routeId: string) => state[SLICE_NAME].tripRoutes
  .find((route) => route?.id === routeId);
export const selectTripById = (state: AppState, tripId: string) => {
  const trips = state[SLICE_NAME].trips || {};
  return trips[tripId];
};

export const tripsReducer = slice.reducer;
