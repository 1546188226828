import React, { useEffect, useState } from 'react';
import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { DateService } from '../../utils/dateService';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import WelcomePage from '../welcome/Welcome';
import Header from './components/base/Header';
import Statistics from './components/base/Statistics';
import BarChart from './components/base/BarChart';
import Feed from './components/base/Feed';
import Appointments from './components/base/Appointments';
import Projects from './components/base/Projects';
import { selectBoardsObject } from '../../redux/slices/settings';
import { THEME_PALETTE_LIGHT } from '../../constants';
import { EntityContainer } from '../../redux/models/core.models';
import BobtailChart from './components/base/BobtailChart';
import EmptyMovesChart from './components/base/EmptyMovesChart';
import useAppDispatch from '../../hooks/useAppDispatch';
import { resetShipments } from '../../redux/slices/shipment-list/shipment-list';

const getColour = (colours: string[], selected: string[]) => {
  if (colours.length === 0) return THEME_PALETTE_LIGHT.primary;
  const remaining = colours.filter((colour) => !selected.includes(colour));
  const randomIndex = Math.floor(Math.random() * remaining.length);
  return remaining[randomIndex];
};

export default function Dashboard() {
  const dispatch = useAppDispatch();
  const features = useFeatureFlags();
  const boards = useSelector(selectBoardsObject);
  
  const [startDate, setStartDate] = useState(DateService.getStartOfTheDayISO());
  const [endDate, setEndDate] = useState(DateService.getEndOfTheDayISO());

  const isStatsEnabled = features.dashboard?.is_enabled || false;
  const colourContainer: EntityContainer<string> = {};
  const allColours = Object.values(THEME_PALETTE_LIGHT).filter((colour) => {
    return colour !== '#fff' && colour !== '#000' && !colour.includes('gray');
  });
  const boardData = Object.values(boards);
  const boardColours = boardData.reduce((store, board) => {
    const selected = Object.values(store);
    const colour = getColour(allColours, selected);
    return {
      ...store,
      [board.entity_id]: colour,
    };
  }, colourContainer);

  const handleDate = (from: string, to: string) => {
    setStartDate(from);
    setEndDate(to);
  };

  useEffect(() => {
    dispatch(resetShipments());
  }, [dispatch]);

  if (!isStatsEnabled) {
    return <WelcomePage />
  }
  return (
    <Container fluid className="p-0">
      <Header title="Dashboard" handleDate={handleDate} />
      <Statistics dateFrom={startDate} dateTo={endDate} />
      <Row>
        <Col lg="8" className="d-flex">
          <BarChart dateFrom={startDate} dateTo={endDate} />
        </Col>
        <Col lg="4" className="d-flex">
          <Feed />
        </Col>
      </Row>
      <Row>
        <Col lg="6" xl="4" className="d-flex">
          <BobtailChart
            boards={boardData}
            boardColours={boardColours}
            dateFrom={startDate}
            dateTo={endDate}
          />
        </Col>
        <Col lg="6" xl="4" className="d-flex">
          <EmptyMovesChart
            boards={boardData}
            boardColours={boardColours}
            dateFrom={startDate}
            dateTo={endDate}
          />
        </Col>
        <Col lg="6" xl="4" className="d-flex">
          <Appointments dateFrom={startDate} dateTo={endDate} />
        </Col>
      </Row>
      <Projects boards={boards} dateFrom={startDate} dateTo={endDate} />
    </Container>
  );
}
