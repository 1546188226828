/* eslint-disable */
import React from 'react'
import { Form } from 'react-bootstrap'

const EditableSelect: React.FC<{ value: string; onChange: (value: string) => void }> = ({ value, onChange }) => (
  <Form.Select value={value} onChange={(e) => onChange(e.target.value)}>
    <option value="Not Started">Not Started</option>
    <option value="In Progress">In Progress</option>
    <option value="Completed">Completed</option>
  </Form.Select>
)

export default EditableSelect;