import React, { useEffect, useState } from 'react';
import { Accordion, Card, Stack } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { TripTableColumns } from '../../../redux/models/board.models';
import { TripBoardShipment } from '../../../redux/models/shipment.models';
import ShipmentsTable from './ShipmentsTable';

interface Props {
  shipments: TripBoardShipment[];
  headers: TripTableColumns[];
  shouldExpandAllItems: boolean;
}

export default function ShipmentCard({
  shipments,
  headers,
  shouldExpandAllItems,
}: Props) {
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const [isItemExpanded, setIsItemExpanded] = useState(shouldExpandAllItems);
  const [title] = useState('Shipments');
  const openShipmentPage = (id: string) => {
    navigate(`/${orgCode}/shipments/${id}`);
  };
  useEffect(() => {
    setIsItemExpanded(shouldExpandAllItems);
  }, [shouldExpandAllItems, headers]);
  return (
    <Accordion activeKey={isItemExpanded ? '0' : ''}>
      <Card>
        <Accordion.Item eventKey="0">
          <Card.Header
            className="group-card-header"
          >
            <Stack direction="horizontal" gap={1} style={{ height: '30px' }}>
              <Card.Title className="group-card-title">
              <Stack direction="horizontal" gap={1}>
                <h4>{title}</h4>
                {/* {isItemExpanded && ` (${groupTrips.length || 0})`} */}
                {/* {isItemExpanded ? groupName : `${groupName} (${groupTrips.length || 0})`} */}
              </Stack>
              </Card.Title>
            </Stack>
            <div
              data-cy={`${title}_group_collapse_btn`}
              className="custom-collapse-icon-wrapper"
              onClick={() => setIsItemExpanded(!isItemExpanded)}
            >
              <FontAwesomeIcon
                width={25}
                height={25}
                icon={faChevronDown}
                className="custom-collapse-icon"
              />
            </div>
          </Card.Header>
          <Accordion.Body style={{ background: 'white', padding: 0 }}>
            <Card.Body>
              <ShipmentsTable headers={headers} shipments={shipments} title={title} onOpen={openShipmentPage} /> 
            </Card.Body>
          </Accordion.Body>
        </Accordion.Item>
      </Card>
    </Accordion>
  );
}
