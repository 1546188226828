import { Button, Dropdown } from 'react-bootstrap'
import { MoreVertical, ChevronRight, ChevronDown } from 'react-feather'
// import EditableCell from '../cells/EditableCell'
import { DangerousGoodsIcon } from '../../../../icons/ShipmentIcons'
import DataRow from './DataRow'
import TripStatusCell from '../cells/TripStatsCell'
import TimelinessBadge from '../../../../../components/timeliness/TimelinessBadge'
import TripTempCell from '../cells/TripTempCell'
import TripAptCell from '../cells/TripAptCell'
import { TripTableColumns } from '../../../../../redux/models/board.models'
import { TripBoardModel } from '../../../../../redux/models/trip.models'

interface Props {
  column: TripTableColumns;
  trip: TripBoardModel;
  isExpanded: boolean;
  boardDate: string;
  isDateRange: boolean;
  onToggleExpand: (id: string) => void;
  onMoveExpand: (id: string) => void;
  onDispatch: (id: string) => void;
}

function TripCell({
  column, trip, isExpanded, boardDate, isDateRange, onToggleExpand, onMoveExpand, onDispatch
}: Props) {
  if (column.key === 'expand') {
    return (
      <Button
        variant="link"
        onClick={() => onToggleExpand(trip.id)}
        aria-label={isExpanded ? "Collapse row" : "Expand row"}
      >
        {isExpanded ? <ChevronDown size={18} /> : <ChevronRight size={18} />}
      </Button>
    )
  }
  if (column.key === 'dangerous_goods' && trip.dangerous_goods) {
    return <DangerousGoodsIcon />
  }
  if (column.key === 'apt') {
    return <TripAptCell trip={trip} boardDate={boardDate} isDateRange={isDateRange} />
  }
  if (column.key === 'status') {
    return <TripStatusCell status={trip.status} />
  }
  if (column.key === 'temp') {
    return <TripTempCell trip={trip} />
  }
  if (column.key === 'timeliness') {
    return <TimelinessBadge scheduled={trip.scheduled_route_end_time} actual={trip.actual_route_end_time} />
  }
  if (column.key === 'route') {
    return (
      <div onClick={() => onMoveExpand(trip.id)} style={{ cursor: 'text' }}>
        {trip.route}
      </div>
    )
  }
  if (column.key === 'customer') {
    return (
      <div>
        {trip.customer}
      </div>
    )
  }
  if (column.key === 'actions') {
    return (
      <Dropdown>
        <Dropdown.Toggle variant="link" id={`dropdown-task-${trip.id}`}>
          <MoreVertical size={18} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => onDispatch(trip.id)}>Dispatch</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }
  return <DataRow data={trip} column={column}  key={column.key}/>
}

export default TripCell;