/* eslint-disable @typescript-eslint/no-explicit-any */
import { Card, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import {
  updateInvoiceDetails, setAreInvoiceDetailsLoading, updateInvoice,
} from '../../../redux/slices/invoices';
import PageFooterButtons from '../../../components/shared/PageFooterBtns';
import InvoiceForm from './InvoiceForm';
import LineItemsTable from '../InvoicesTable/LineItemsTable';
import { formatCurrency } from '../utils/invoice.utils';
import InvoiceShipments from './InvoiceShipments';
import { Invoice } from '../../../redux/models/invoice.models';
import Analytics from '../../../utils/analytics';
import { isPermissions } from '../../../redux/slices/settings.utils';
import { FeatureResource, ResourcePermission } from '../../../redux/models/feature.flags.models';
import Permission from '../../../components/shared/permissions/Permission';
import { getPermissions } from '../../../config';

const page = 'invoiceDetails';
const WriteInvoicePermissions = {
  [FeatureResource.Invoice]: ResourcePermission.Write,
};

const ReadShipmentPermissions = {
  [FeatureResource.Shipment]: ResourcePermission.Read,
};

export default function InvoiceDetails({ invoiceDetails }: { invoiceDetails: Invoice }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const permissions = getPermissions();

  const isEditable = isPermissions(orgCode, permissions, WriteInvoicePermissions);
  const {
    bill_to: billTo, customer, total, line_items: lineItems, shipments, currency,
  } = invoiceDetails.data;
  const customerName = customer.data.name;
  const billToClient = billTo.data.name;

  const displayCustomerName = `Customer: ${customerName || ''}`;
  const displayBillTo = `Bill To: ${billToClient || ''}`;
  const displaySubtotal = `Subtotal: ${formatCurrency(currency, total) || ''} ${currency}`;

  const handleCancel = () => navigate(-1);

  const handleSave = async () => {
    dispatch(setAreInvoiceDetailsLoading(true));
    try {
      await updateInvoice(invoiceDetails);
      const successMessage = 'Invoice was updated successfully.';
      toast(successMessage, { type: 'success' });
      navigate(-1);
    } catch (e) {
      if (e instanceof Error) {
        const errorMessage = `Couldn't update invoice. ${e.message}. Please contact support if the problem persists.`;
        toast(errorMessage, { type: 'error' });
      }
      Analytics.capture(e);
    } finally {
      dispatch(setAreInvoiceDetailsLoading(false));
    }
  };
  const handleForm = (prop: string, value: any) => {
    const updates = {
      ...invoiceDetails,
      data: {
        ...invoiceDetails.data,
        [prop]: value,
      },
    };
    dispatch(updateInvoiceDetails(updates));
  };

  return (
    <>
      <Card>
        <Card.Body className="invoice-header">
          <Row className="mt-4">
            <Col sm={8} className={`${page}-billing-info`}>
              <h3>{displayCustomerName}</h3>
              <h3>{displayBillTo}</h3>
            </Col>
            <InvoiceForm invoiceDetails={invoiceDetails} handleForm={handleForm} isEditable={isEditable} page={page} />
          </Row>
        </Card.Body>
        <Card.Body className="invoice-lineItems">
          <LineItemsTable lineItems={lineItems} shipments={shipments} page={page} currency={currency} />
          <h3 id="subtotal" data-cy="invoice_subtotal">{displaySubtotal}</h3>
        </Card.Body>
      </Card>
      <Permission resources={ReadShipmentPermissions}>
        <InvoiceShipments page={page} shipments={shipments} isEditable={isEditable} />
      </Permission>
      <PageFooterButtons
        handleSave={handleSave}
        handleClose={handleCancel}
        page={page}
        deleteButton={false}
        disabled={false}
        isEditable={isEditable}
      />
    </>
  );
}
