/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable import/no-cycle */
/* eslint-disable  no-restricted-syntax */
import {
  FILTER_STORAGE_PREFIX,
  REFRESH_SETTINGS_HOURS,
  SETTINGS_STORAGE_PREFIX,
} from '../constants/core.constants';
import { DateService } from './dateService';

export const formatBoardsToSidebarLinks = (boards, orgCode) => boards
  .map((board) => ({
    title: board.data.name,
    href: `/${orgCode}/boards/${board.entity_id}`,
  }))
  .sort((a, b) => a.title.localeCompare(b.title));

const getItemData = (key, item) => {
  if (key && item && item.data && item.data[key]) {
    return item.data[key];
  }
  return '';
};

export const createDropdownOptions = (items, nameFieldKey = 'name', detailFieldKey = '') => items
  .map((item) => {
    const data = {
      value: item.entity_id,
      label: getItemData(nameFieldKey, item),
    };
    if (detailFieldKey !== '') {
      const detailText = getItemData(detailFieldKey, item);
      if (detailText && detailText !== '') {
        data.detail = detailText;
      }
    }
    return data;
  })
  .sort((a, b) => a?.label?.localeCompare(b?.label));

export const createShipmentIdsDropdownOptions = (items, addressesObject, nameFieldKey = 'name') => {  
  return items
    .map((item) => {
      const origin = addressesObject[item.data?.origin_id]?.data?.samsara_name || 'Origin';
      const destination = addressesObject[item.data?.destination_id]?.data?.samsara_name || 'Destination';
      const shipmentName = `${item.data[nameFieldKey]} ${origin} >> ${destination}`;
      const containerNo = item?.data?.container_no || '';
      const isContainer = containerNo && containerNo.trim() !== '';
      return {
        value: item.entity_id,
        label: isContainer ? item.data.container_no: shipmentName,
      };
    }).sort((a, b) => a?.label?.localeCompare(b?.label));
}
export const safelyParseJSON = (json) => {
  let parsed;
  try {
    parsed = JSON.parse(json);
  } catch (e) {
    return null;
  }
  return parsed;
};

export const removeDuplicates = (arr, prop) => arr.filter((v, i, a) => a.findIndex((v2) => v[prop] === v2[prop]) === i);

export const debounce = (func) => {
  let timer;
  // eslint-disable-next-line func-names
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 500);
  };
};

export const getSelectedOptions = (entityId, optionsList) => optionsList.filter((option) => option.value === entityId);

export const groupArrayByKey = (list, keyGetter) => {
  const map = {};
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map[key];
    if (!collection) {
      map[key] = [item];
    } else {
      collection.push(item);
    }
  });
  return map;
};

/**
 * ======================== Session Storage ========================
 */

export const saveFilterValueToSessionStorage = (filterName, filterValue) => {
  sessionStorage.setItem(`${FILTER_STORAGE_PREFIX}${filterName}`, filterValue);
};

export const getFilterValueFromSessionStorage = (filterName) => {
  const filter = sessionStorage.getItem(`${FILTER_STORAGE_PREFIX}${filterName}`);
  return filter;
};

export const saveValueToSessionStorage = (key, value) => {
  const strVal = JSON.stringify(value);
  sessionStorage.setItem(`${key}`, strVal);
};

export const getValueFromSessionStorage = (key) => {
  const item = sessionStorage.getItem(`${key}`);
  return safelyParseJSON(item);
};

/**
 * ======================== Local Storage ========================
 */

export const saveFilterValueToLocalStorage = (filterName, filterValue) => {
  localStorage.setItem(`${FILTER_STORAGE_PREFIX}${filterName}`, filterValue);
};

export const getFilterValueFromLocalStorage = (filterName) => {
  const item = localStorage.getItem(`${FILTER_STORAGE_PREFIX}${filterName}`);
  return item;
};

export const saveSettingToLocalStorage = (settingName, settingValue) => {
  const value = JSON.stringify({ list: settingValue, lastUpdated: new Date() });
  localStorage.setItem(`${SETTINGS_STORAGE_PREFIX}${settingName}`, value);
};

export const getSettingFromLocalStorage = (settingName) => {
  const settingItem = localStorage.getItem(`${SETTINGS_STORAGE_PREFIX}${settingName}`);
  return safelyParseJSON(settingItem);
};

export const removeItemFromLocalStorage = (settingName) => {
  const item = getSettingFromLocalStorage(settingName);
  if (item) localStorage.removeItem(`${SETTINGS_STORAGE_PREFIX}${settingName}`);
}

export const saveValueToLocalStorage = (key, value) => {
  const strValue = JSON.stringify(value);
  localStorage.setItem(`${key}`, strValue);
};

export const getValueFromLocalStorage = (key) => {
  const settingItem = localStorage.getItem(`${key}`);
  return safelyParseJSON(settingItem);
};

export const getSettingFromServer = ({ name, request, savedSetting }) => new Promise((resolve, reject) => {
  const duration = DateService.getDifferenceInHours(savedSetting?.lastUpdated || 0);
  if (duration <= REFRESH_SETTINGS_HOURS) {
    resolve({ list: savedSetting.list, lastUpdated: savedSetting.lastUpdated });
  } else {
    request()
      .then((response) => {
        const {
          data: { data },
        } = response;
        saveSettingToLocalStorage(name, data);
        resolve({ list: data, lastUpdated: `${new Date()}` });
      })
      .catch((e) => {
        reject(new Error(`Error loading ${name}. ${e.message}. Try again later`));
      });
  }
});

export const capitalizeFirstLetter = (string = '') => string.charAt(0).toUpperCase() + string.slice(1);

export const getCachedFromSession = (cacheKey) => {
  const data = getValueFromSessionStorage(cacheKey);
  if (data) {
    return data;
  }
  return null;
};

export const deepEqual = (obj1, obj2) => {
  // If both are the same object reference or are strictly equal,
  // return true
  if (obj1 === obj2) {
    return true;
  }

  // If either is not an object or is null, return false
  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }

  // Get the keys of each object
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // If they don't have the same number of keys, return false
  if (keys1.length !== keys2.length) {
    return false;
  }

  // For each key in the first object
  for (const key of keys1) {
    // If the second object doesn't have the key or their values aren't deeply equal, return false
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  // If none of the above conditions were met, the objects are deeply equal
  return true;
};

export const formatOrgName = (org) => {
  if (!org) return 'No org name';
  return org
      // Step 1: Split the string into words
      .split('-')
      // Step 2: Capitalize the first letter of each word
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      // Step 3: Join the words back into a string with spaces
      .join(' ');
};

export const arraysAreEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  
  const set1 = new Set(arr1);
  const set2 = new Set(arr2);
  
  if (set1.size !== set2.size) {
    return false;
  }

  for (const item of set1) {
    if (!set2.has(item)) {
      return false;
    }
  }

  return true;
};
