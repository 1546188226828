/* eslint-disable @typescript-eslint/no-explicit-any */
import { Shipment } from '../redux/models/shipment.models';
import axiosInstance from '../utils/axios';

import { AquadashServiceConfigProvider } from './AquadashServiceConfigProvider';

export class ShipmentServiceApiProvider {
  static createShipment(postBody = {
    type: 'reefer-van',
    customer_id: '',
    billing_type_id: '',
    status: 'booked',
    trip_ids: [],
  }) {
    return axiosInstance.post(AquadashServiceConfigProvider.createShipment(), postBody);
  }

  static createBoardShipment(body: any) {
    return axiosInstance.post(AquadashServiceConfigProvider.createBoardShipment(), body);
  }

  static getShipments(filters: any) {
    return axiosInstance.get(AquadashServiceConfigProvider.getShipments(filters));
  }

  static getShipmentsAllDetails(filters: any) {
    return axiosInstance.get(AquadashServiceConfigProvider.getShipmentsAllDetails(filters));
  }

  static getShipmentDetails(shipmentId: string) {
    return axiosInstance.get(AquadashServiceConfigProvider.getShipmentDetails(shipmentId));
  }

  static updateShipment(shipment: Shipment) {
    return axiosInstance.put(AquadashServiceConfigProvider.updateShipment(shipment.entity_id), shipment);
  }

  static deleteShipment(shipmentId: string) {
    return axiosInstance.delete(AquadashServiceConfigProvider.deleteShipment(shipmentId));
  }
}