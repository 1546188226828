/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-syntax */
import { toast } from 'react-toastify';
import { SHIPMENT_STATUS_TYPES } from '../../pages/shipments/constants/shipments.constants';
import { DateService } from '../../utils/dateService';
import { EntityContainer } from '../models/core.models';
import {
  Shipment, ShipmentData, ShipmentGroup, ShipmentListView, ShipmentTypeFieldData, FormFieldConfig, ShipmentListFilters
} from '../models/shipment.models';
import { ApiQuery, ElementType, FilterOp, QueryFilter, QueryOperator } from '../models/network.models';
import { getRelQuery, getSearchQuery, getStringQuery } from './network.utils';

const getFilters = (params: ShipmentListFilters) => {
  const filters: QueryFilter[] = [];
  if (params.containerNo !== '') {
    filters.push({
      field: {
        id: 'Container No.',
        name: 'container_no',
        type: ElementType.String,
      },
      op: FilterOp.Regex,
      value: params.containerNo,
    })
  }
  return filters;
}

export const formatFindBody = (params: ShipmentListFilters): ApiQuery => {
  const filters = getFilters(params);
  return {
    filters,
    operator: QueryOperator.And,
  }
}

const formatStartDate = (shipmentData: ShipmentData) => {
  if (shipmentData && shipmentData.dates) {
    const startDate = shipmentData.dates.start_date || '';
    return DateService.getDoMMMFormat(startDate);
  }
  return '';
};

const formatUnix = (shipmentData: ShipmentData) => {
  if (shipmentData && shipmentData.dates) {
    const startDate = shipmentData.dates.start_date || '';
    return DateService.getUnixTime(startDate);
  }
  return 0;
};

export const formatShipments = (
  shipments: Shipment[],
  contactsObject: EntityContainer<any>,
  billingTypeObject: EntityContainer<any>,
  addressesObject: EntityContainer<any>,
) => [...shipments].map((shipment): ShipmentListView => ({
  id: shipment.entity_id,
  assignee_id: shipment.data.assignee_id || '',
  customer: contactsObject[shipment.data?.customer_id]?.data?.name || '',
  shipment_no: shipment.data?.shipment_no,
  container_no: shipment.data?.container_no,
  billing_type: billingTypeObject[shipment.data?.billing_type_id]?.data?.name || '',
  type: shipment.data.type || '',
  type_id: shipment.data.type_id || '',
  origin: addressesObject[shipment.data?.origin_id]?.data?.samsara_name || 'Origin',
  destination: addressesObject[shipment.data?.destination_id]?.data?.samsara_name || 'Destination',
  status: shipment.data?.status || '',
  start_date: formatStartDate(shipment.data || {}),
  purchase_order_no: shipment.data?.purchase_order_no || '',
  unix: formatUnix(shipment.data || {}),
})).sort((a, b) => b.unix - a.unix);

export const clientFilterShipments = (
  list: ShipmentListView[],
  customerParam: string,
  statusParam: string,
  origin: string,
  destination: string,
  type: string,
  po: string,
) => list
  .filter((shipment) => (shipment.customer === customerParam)
    && (shipment.status === statusParam)
    && (shipment.origin === origin)
    && (shipment.type === type)
    && (shipment.destination === destination)
    && (shipment.purchase_order_no === po));

const getStatus = (value: string) => {
  const option = Object.values(SHIPMENT_STATUS_TYPES).find((type) => {
    return type.value === value
  });
  return option ? option.label : '';
}

const getGroupId = (groupType: string, prop: string) => {
  if (groupType === 'status') {
    return getStatus(prop);
  }
  return prop === 'undefined' ? 'Not Set' : prop;
}

export const groupBy = (prop: string, shipments: ShipmentListView[]) => {
  const container: EntityContainer<ShipmentListView[]> =
    shipments.reduce((store: EntityContainer<ShipmentListView[]>, shipment: EntityContainer<any>) => {
      const key = shipment[prop];
      return {
        ...store,
        [key]: store[key] ? [...store[key], shipment] : [shipment],
      }
    }, {});

  const groups: ShipmentGroup[] = [];
  for (const [id, shipmentGroup] of Object.entries(container)) {
    const groupId = getGroupId(prop, id);
    groups.push({
      id: groupId,
      shipments: shipmentGroup,
    });
  }
  groups.sort((a: ShipmentGroup, b: ShipmentGroup) => a.id.localeCompare(b.id));
  return groups;
}

const getErrorPlaceholder = (verb: string) =>
  `Couldn't ${verb} shipments. Please contact support if the problem persists`;

export const getError = (e: unknown, details = "Couldn't get shipments", verb = 'get'): any => {
  let em;
  if (e instanceof Error) {
    em = e.message;
  }
  const errorMessage = em ?
    `${details}. ${em}. Please contact support if the problem persists.`
    : getErrorPlaceholder(verb);
  return toast(errorMessage, { type: 'error' });
};

const { Bool, DateTime, Number, Rel, Select, String } = ElementType;

export const getShipmentFieldType = (key: string) => {
  if (key === 'billing_type_id') return Rel;
  if (key === 'bol') return String;
  if (key === 'booking_no') return String;
  if (key === 'container_no') return String;
  if (key === 'container_size') return Select;
  if (key === 'consignee_id') return Rel;
  if (key === 'customer_id') return Rel;
  if (key === 'cut_off_date') return DateTime;
  if (key === 'dangerous_goods') return Bool;
  if (key === 'delivery_order_no') return String;
  if (key === 'destination_id') return Rel;
  if (key === 'empty_release_depot') return Rel;
  if (key === 'erd') return String;
  if (key === 'last_free_date') return String;
  if (key === 'notes') return String;
  if (key === 'origin_id') return Rel;
  if (key === 'paps') return String;
  if (key === 'pars') return String;
  if (key === 'port_id') return Rel;
  if (key === 'product_name') return Select;
  if (key === 'purchase_order_no') return String;
  if (key === 'quantity') return Number;
  if (key === 'quantity_uom_id') return Rel;
  if (key === 'seal_no') return String;
  if (key === 'reefer_settings') return Select;
  if (key === 'requested_drop_off_date') return DateTime;
  if (key === 'requested_pick_up_date') return DateTime;
  if (key === 'shipping_line_id') return Rel;
  if (key === 'temp') return Number;
  if (key === 'vessel') return String;
  if (key === 'weight') return Number;
  if (key === 'weight_uom_id') return Rel;
  return ElementType.String
};

export const defaultShipmentTypeFields: ShipmentTypeFieldData = {
  billing_type_id: {
    name: 'Quote Template',
    key: 'billing_type_id',
    required: false,
    type: getShipmentFieldType('billing_type_id'),
    visible: true,
  },
  bol: {
    name: 'B.O.L.',
    key: 'bol',
    required: false,
    type: getShipmentFieldType('bol'),
    visible: true,
  },
  booking_no: {
    name: 'Booking #',
    key: 'booking_no',
    required: false,
    type: getShipmentFieldType('booking_no'),
    visible: true,
  },
  container_no: {
    name: 'Container #',
    key: 'container_no',
    required: false,
    type: getShipmentFieldType('container_no'),
    visible: false,
  },
  container_size: {
    name: 'Container Size',
    key: 'container_size',
    required: false,
    type: getShipmentFieldType('container_size'),
    visible: false,
  },
  consignee_id: {
    name: 'Consignee',
    key: 'consignee_id',
    required: false,
    type: getShipmentFieldType('consignee_id'),
    visible: true,
  },
  customer_id: {
    name: 'Customer',
    key: 'customer_id',
    required: false,
    type: getShipmentFieldType('customer_id'),
    visible: true,
  },
  cut_off_date: {
    name: 'Cut Off Date',
    key: 'cut_off_date',
    required: false,
    type: getShipmentFieldType('cut_off_date'),
    visible: false,
  },
  dangerous_goods: {
    name: 'Dangerous Goods',
    key: 'dangerous_goods',
    required: false,
    type: getShipmentFieldType('dangerous_goods'),
    visible: true,
  },
  delivery_order_no: {
    name: 'D.O. #',
    key: 'delivery_order_no',
    required: false,
    type: getShipmentFieldType('delivery_order_no'),
    visible: false,
  },
  destination_id: {
    name: 'Destination',
    key: 'destination_id',
    required: false,
    type: getShipmentFieldType('destination_id'),
    visible: true,
  },
  empty_release_depot: {
    name: 'Empty Release Depot',
    key: 'empty_release_depot',
    required: false,
    type: getShipmentFieldType('empty_release_depot'),
    visible: false,
  },
  erd: {
    name: 'ERD',
    key: 'erd',
    required: false,
    type: getShipmentFieldType('erd'),
    visible: false,
  },
  last_free_date: {
    name: 'Last Free Day',
    key: 'last_free_date',
    required: false,
    type: getShipmentFieldType('last_free_date'),
    visible: false,
  },
  notes: {
    name: 'Notes',
    key: 'notes',
    required: false,
    type: getShipmentFieldType('notes'),
    visible: true,
  },
  origin_id: {
    name: 'Origin',
    key: 'origin_id',
    required: false,
    type: getShipmentFieldType('origin_id'),
    visible: true,
  },
  paps: {
    name: 'PAPs',
    key: 'paps',
    required: false,
    type: getShipmentFieldType('paps'),
    visible: true,
  },
  pars: {
    name: 'PARs',
    key: 'pars',
    required: false,
    type: getShipmentFieldType('pars'),
    visible: true,
  },
  port_id: {
    name: 'Port',
    key: 'port_id',
    required: false,
    type: getShipmentFieldType('port_id'),
    visible: false,
  },
  product_name: {
    name: 'Product',
    key: 'product_name',
    required: false,
    type: getShipmentFieldType('product_name'),
    visible: true,
  },
  purchase_order_no: {
    name: 'P.O.#',
    key: 'purchase_order_no',
    required: false,
    type: getShipmentFieldType('purchase_order_no'),
    visible: true,
  },
  quantity: {
    name: 'Quantity',
    key: 'quantity',
    required: false,
    type: getShipmentFieldType('quantity'),
    visible: true,
  },
  quantity_uom_id: {
    name: 'Quantity Unit',
    key: 'quantity_uom_id',
    required: false,
    type: getShipmentFieldType('quantity_uom_id'),
    visible: true,
  },
  reefer_settings: {
    name: 'Reefer Settings',
    key: 'reefer_settings',
    required: false,
    type: getShipmentFieldType('reefer_settings'),
    visible: true,
  },
  requested_drop_off_date: {
    name: 'Requested Drop Off',
    key: 'requested_drop_off_date',
    required: false,
    type: getShipmentFieldType('requested_drop_off_date'),
    visible: false,
  },
  requested_pick_up_date: {
    name: 'Requested Pick Up',
    key: 'requested_pick_up_date',
    required: false,
    type: getShipmentFieldType('requested_pick_up_date'),
    visible: false,
  },
  seal_no: {
    name: 'Seal #',
    key: 'seal_no',
    required: false,
    type: getShipmentFieldType('seal_no'),
    visible: false,
  },
  shipping_line_id: {
    name: 'Shipping Line',
    key: 'shipping_line_id',
    required: false,
    type: getShipmentFieldType('shipping_line_id'),
    visible: false,
  },
  temp: {
    name: 'Temp',
    key: 'temp',
    required: false,
    type: getShipmentFieldType('temp'),
    visible: true,
  },
  vessel: {
    name: 'Vessel',
    key: 'vessel',
    required: false,
    type: getShipmentFieldType('vessel'),
    visible: false,
  },
  weight: {
    name: 'Weight',
    key: 'weight',
    required: false,
    type: getShipmentFieldType('weight'),
    visible: true,
  },
  weight_uom_id: {
    name: 'Weight Unit',
    key: 'weight_uom_id',
    required: false,
    type: getShipmentFieldType('weight_uom_id'),
    visible: true,
  },
};

export const getShipmentFieldData = (fields: EntityContainer<FormFieldConfig>): ShipmentTypeFieldData => {
  return {
    billing_type_id: fields.billing_type_id || defaultShipmentTypeFields.billing_type_id,
    bol: fields.bol || defaultShipmentTypeFields.bol,
    booking_no: fields.booking_no || defaultShipmentTypeFields.booking_no,
    // clearance_no: fields.clearance_no || defaultShipmentTypeFields.clearance_no,
    container_no: fields.container_no || defaultShipmentTypeFields.container_no,
    container_size: fields.container_size || defaultShipmentTypeFields.container_size,
    consignee_id: fields.consignee_id || defaultShipmentTypeFields.consignee_id,
    customer_id: fields.customer_id || defaultShipmentTypeFields.customer_id,
    cut_off_date: fields.cut_off_date || defaultShipmentTypeFields.cut_off_date,
    dangerous_goods: fields.dangerous_goods || defaultShipmentTypeFields.dangerous_goods,
    delivery_order_no: fields.delivery_order_no || defaultShipmentTypeFields.delivery_order_no,
    destination_id: fields.destination_id || defaultShipmentTypeFields.destination_id,
    empty_release_depot: fields.empty_release_depot || defaultShipmentTypeFields.empty_release_depot,
    erd: fields.erd || defaultShipmentTypeFields.erd,
    last_free_date: fields.last_free_date || defaultShipmentTypeFields.last_free_date,
    notes: fields.notes || defaultShipmentTypeFields.notes,
    origin_id: fields.origin_id || defaultShipmentTypeFields.origin_id,
    paps: fields.paps || defaultShipmentTypeFields.paps,
    pars: fields.pars || defaultShipmentTypeFields.pars,
    port_id: fields.port_id || defaultShipmentTypeFields.port_id,
    product_name: fields.product_name || defaultShipmentTypeFields.product_name,
    purchase_order_no: fields.purchase_order_no || defaultShipmentTypeFields.purchase_order_no,
    quantity: fields.quantity || defaultShipmentTypeFields.quantity,
    quantity_uom_id: fields.quantity_uom_id || defaultShipmentTypeFields.quantity_uom_id,
    reefer_settings: fields.reefer_settings || defaultShipmentTypeFields.reefer_settings,
    requested_drop_off_date: fields.requested_drop_off_date || defaultShipmentTypeFields.requested_drop_off_date,
    requested_pick_up_date: fields.requested_pick_up_date || defaultShipmentTypeFields.requested_pick_up_date,
    seal_no: fields.seal_no || defaultShipmentTypeFields.seal_no,
    shipping_line_id: fields.shipping_line_id || defaultShipmentTypeFields.shipping_line_id,
    temp: fields.temp || defaultShipmentTypeFields.temp,
    vessel: fields.vessel || defaultShipmentTypeFields.vessel,
    weight: fields.weight || defaultShipmentTypeFields.weight,
    weight_uom_id: fields.weight_uom_id || defaultShipmentTypeFields.weight_uom_id
  };
};

export const createShipmentType = (fields: string[]): ShipmentTypeFieldData => {
  return Object.values(defaultShipmentTypeFields).reduce((store, field) => {
    return {
      ...store,
      [field.key]: {
        ...field,
        visible: fields.includes(field.key),
      },
    };
  }, defaultShipmentTypeFields);
};

const DEFAULT_FIELDS = [
  'customer_id', 'billing_type_id', 'product', 'purchase_order_no', 'notes', 'bol', 'dangerous_goods'
];

const BULK_FIELDS = [
  'product_name',
  'purchase_order_no',
  'booking_no',
  'delivery_order_no',
  'seal_no',
  'quantity',
  'quantity_uom_id',
  'weight',
  'weight_uom_id',
  'temp',
  'destination_id',
  'origin_id',
  ...DEFAULT_FIELDS,
];

const EXP_FIELDS = [
  'product_name',
  'purchase_order_no',
  'booking_no',
  'seal_no',
  'quantity',
  'quantity_uom_id',
  'weight',
  'weight_uom_id',
  'port_id',
  'vessel',
  'container_size',
  'container_no',
  'shipping_line',
  'empty_release_depot',
  'cut_off_date',
  'erd',
  ...DEFAULT_FIELDS,
];

const IMP_FIELDS = [
  'product_name',
  'purchase_order_no',
  'booking_no',
  'seal_no',
  'port_id',
  'vessel',
  'container_size',
  'container_no',
  'shipping_line',
  'last_free_date',
  'quantity',
  'quantity_uom_id',
  'weight',
  'weight_uom_id',
  ...DEFAULT_FIELDS,
];

const REEFER_FIELDS = [
  'product_name',
  'purchase_order_no',
  'booking_no',
  'booking_no',
  'quantity',
  'quantity_uom_id',
  'weight',
  'weight_uom_id',
  'destination_id',
  'origin_id',
  'purchase_order_no',
  'temp',
  'reefer_settings',
  ...DEFAULT_FIELDS,
];

const SOUTHBOUND_FIELDS = [
  'consignee_id',
  'product_name',
  'purchase_order_no',
  'booking_no',
  'quantity',
  'quantity_uom_id',
  'weight',
  'weight_uom_id',
  'destination_id',
  'origin_id',
  'purchase_order_no',
  'temp',
  'reefer_settings',
  'paps',
  ...DEFAULT_FIELDS,
];

const SHIPMENT_FIELDS: EntityContainer<ShipmentTypeFieldData> = {
  bulk: createShipmentType(BULK_FIELDS),
  'export-containers': createShipmentType(EXP_FIELDS),
  'import-containers': createShipmentType(IMP_FIELDS),
  'reefer-van': createShipmentType(REEFER_FIELDS),
  southbound: createShipmentType(SOUTHBOUND_FIELDS),
}

export const getShipmentFields = (type: string) => {
  return SHIPMENT_FIELDS[type] || defaultShipmentTypeFields;
}

// ==================== Shipment List Filters ====================

export const getFindShipmentFilters = (filters: ShipmentListFilters): QueryFilter[] => {
  const query: QueryFilter[] = [];
  if (filters.containerNo !== '') {
    query.push(getSearchQuery('container_no', filters.containerNo));
  }
  if (filters.customer !== '') {
    query.push(getRelQuery('customer_id', filters.customer));
  }
  if (filters.loadType !== '') {
    query.push(getRelQuery('billing_type_id', filters.loadType));
  }
  if (filters.shipmentTypeId !== '') {
    query.push(getRelQuery('type_id', filters.shipmentTypeId));
  }
  if (filters.status !== '') {
    query.push(getStringQuery('status', filters.status));
  }
  if (filters.destination !== '') {
    query.push(getRelQuery('destination_id', filters.destination));
  }
  if (filters.origin !== '') {
    query.push(getRelQuery('origin_id', filters.origin));
  }
  if (filters.purchaseOrder !== '') {
    query.push(getSearchQuery('purchase_order_no', filters.purchaseOrder));
  }
  return query;
};