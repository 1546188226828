/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { GroupBase, OptionsOrGroups, SingleValue } from 'react-select';
import { debounce } from 'lodash';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { Form, FormLabel } from 'react-bootstrap';
import { createDropdownOptions, } from '../../../utils/core.utils';
import { searchSettings } from '../../../redux/slices/settings';
import { DropdownOptionsModel } from '../SelectInput.models';

interface Props {
  disabled: boolean;
  labelText: string;
  name: string;
  value: string;
  options: DropdownOptionsModel[];
  emptyStateOptions: DropdownOptionsModel[];
  shouldHaveMargin?: boolean,
  isClearable?: boolean,
  settingName: string;
  dataField: string;
  secondSearch?: string;
  dataCy: string;
  handleChange: (prop: string, val: string) => void;
  handleCreate: (prop: string, val: string) => void;
}

function CreatableSearchInput({
  disabled,
  labelText,
  name,
  value,
  options = [],
  emptyStateOptions = [],
  shouldHaveMargin = true,
  isClearable = true,
  settingName,
  dataField,
  secondSearch,
  handleChange,
  handleCreate,
  dataCy,
}: Props) {
  const selectOptions = value === '' && emptyStateOptions.length ? emptyStateOptions : options;
  const [dropdownOptions, setDropdownOptions] = useState(selectOptions);
  const [isAdding, setIsAdding] = useState(false);
  const selectValue = dropdownOptions.filter((option) => option.value === value);

  useEffect(() => {
    setDropdownOptions(selectOptions);
  }, [selectOptions]);

  const onSearch = (searchValue: string, callback: (options: OptionsOrGroups<DropdownOptionsModel, GroupBase<DropdownOptionsModel>>) => void) => {
    searchSettings(
      settingName,
      searchValue,
      dataField,
      secondSearch,
    ).then((response) => {
      const items = response?.data?.data?.items || [];
      const newOptions: DropdownOptionsModel[] = createDropdownOptions(items, dataField);
      callback(newOptions);
    })
    
    // setDropdownOptions(newOptions);
    // console.log(items);
    // console.log(newOptions);
    // return newOptions;
  };

  const onOptimisedSearch = debounce(onSearch, 400);
  // const onHandleSearch = (searchValue: string, callback: (options: OptionsOrGroups<DropdownOptionsModel, GroupBase<DropdownOptionsModel>>) => void) => {
  //   searchSettings(
  //     settingName,
  //     searchValue,
  //     dataField,
  //     secondSearch,
  //   ).then((response) => {
  //     const items = response?.data?.items || [];
  //     const newOptions: DropdownOptionsModel[] = createDropdownOptions(items, dataField);
  //     console.log(newOptions);
  //     console.log(items);
  //     callback(newOptions);
  //   });
  // };

  const onChange = (valueObj: SingleValue<DropdownOptionsModel>) => {
    setDropdownOptions([
      {
        label: valueObj?.label || '',
        value: valueObj?.value || '',
      }
    ]);
    handleChange(name, valueObj ? valueObj.value : '');
  };
  const onCreate = async (inputValue: string) => {
    await setIsAdding(true);
    await handleCreate(name, inputValue);
    setIsAdding(false);
  };

  return (
    <Form.Group className={`${shouldHaveMargin ? 'mb-3' : ''}`} data-cy={dataCy}>
      {labelText ? (
        <FormLabel>
          <h5>
            {labelText}
          </h5>
        </FormLabel>
      ) : null}
      <AsyncCreatableSelect
        cacheOptions
        defaultOptions
        isClearable={isClearable}
        isSearchable
        isDisabled={disabled || isAdding}
        isLoading={isAdding}
        className={`react-select-container ${name}`}
        classNamePrefix="react-select"
        value={selectValue}
        placeholder="Type..."
        onChange={onChange}
        onCreateOption={onCreate}
        loadOptions={onOptimisedSearch}
      />
    </Form.Group>
  );
}

CreatableSearchInput.defaultProps = {
  shouldHaveMargin: true,
  isClearable: true,
  secondSearch: undefined,
}

export default CreatableSearchInput;