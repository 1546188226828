import '../../../trips/TripShipments/ShipmentCard';

import { Card, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  saveShipmentDetails,
} from '../../../../redux/slices/shipments';
import DocumentsGroup from './DocumentsGroup';
import DeleteModal from '../../../../components/modals/DeleteModal';
import { AquadashServiceApiProvider } from '../../../../services/AquadashServiceApiProvider';
import { removeExtension } from '../../utils/shipments.utils';
import Analytics from '../../../../utils/analytics';
import useFeatureFlags from '../../../../hooks/useFeatureFlags';
import {
  saveShipmentDetails as saveShipmentDetailsPaginated
} from '../../../../redux/slices/shipment-list/shipment-list';

const deletedInitialState = {
  file: {},
  group: null,
  groupAttachments: [],
};

export default function Documents({ shipmentDetails }) {
  const dispatch = useDispatch();
  const features = useFeatureFlags();
  const isPaginationEnabled = features.paginated_shipments?.is_enabled || false;
  const TITLE = `${shipmentDetails?.data?.shipment_no} Documents`;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletedFile, setDeletedFile] = useState(deletedInitialState);

  const {
    delivery_attachments: deliveryAttachments,
    pick_up_attachments: pickUpAttachments,
    misc_attachments: miscAttachments,
  } = shipmentDetails.data;

  const updateShipmentDetails = async (group, arr) => {
    const shipmentDetailsData = {
      ...shipmentDetails,
      data: {
        ...shipmentDetails.data,
        [group]: arr,
      },
    };
    try {
      if (isPaginationEnabled) {
        await dispatch(saveShipmentDetailsPaginated(shipmentDetailsData));
      } else {
        await dispatch(saveShipmentDetails(shipmentDetailsData));
      }
    } catch (e) {
      // const errorMessage = `Couldn't update attachments. ${e.message}.`;
      Analytics.capture(e);
    }
  };

  const handleDelete = (file, group, groupAttachments) => {
    setDeletedFile({
      file,
      group,
      groupAttachments,
    });
    setShowDeleteModal(true);
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setDeletedFile(deletedInitialState);
  };

  const removeAttachmentFile = (groupAttachments, fileName) => {
    const defaultAttachment = groupAttachments.find((attachment) => attachment.name === removeExtension(fileName));
    if (defaultAttachment) {
      return groupAttachments.map((attachment) => {
        if (attachment.name === removeExtension(fileName)) {
          return {
            ...attachment,
            filetype: null,
            path: null,
          };
        }
        return attachment;
      });
    }
    return groupAttachments.filter((attachment) => attachment.name !== fileName);
  };

  const handleDeleteConfirm = async () => {
    const file = [{ name: deletedFile.file.path }];
    try {
      await AquadashServiceApiProvider.deleteFile(file);
      const updatedAttachmentsData = removeAttachmentFile(deletedFile.groupAttachments, deletedFile.file.name);
      updateShipmentDetails(deletedFile.group, updatedAttachmentsData);
    } catch (e) {
      const errorMessage = "Couldn't delete attachment. Please contact support if the problem persists.";
      toast(errorMessage, { type: 'error' });
    } finally {
      setShowDeleteModal(false);
    }
  };

  return (
    <>
      <Card data-cy="shipmentDetails_attachment_card">
        <Card.Body>
          <Row className="mt-2">
            <h4>{TITLE}</h4>
            <DocumentsGroup
              documentGroupName="Pick Up"
              attachments={pickUpAttachments}
              updateShipmentDetails={updateShipmentDetails}
              group="pick_up_attachments"
              handleDelete={handleDelete}
            />
            <DocumentsGroup
              documentGroupName="Delivery"
              attachments={deliveryAttachments}
              updateShipmentDetails={updateShipmentDetails}
              group="delivery_attachments"
              handleDelete={handleDelete}
            />
            <DocumentsGroup
              documentGroupName="Miscellaneous"
              attachments={miscAttachments}
              updateShipmentDetails={updateShipmentDetails}
              group="misc_attachments"
              handleDelete={handleDelete}
            />
          </Row>
        </Card.Body>
      </Card>
      <DeleteModal
        title="Delete Attachment?"
        description={`Would you like to delete ${deletedFile?.file.name}?`}
        shouldShowDeleteModal={showDeleteModal}
        deleteItem={handleDeleteConfirm}
        cancelDeletion={handleDeleteCancel}
      />
    </>
  );
}
