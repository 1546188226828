import { CURRENCY } from '../constants/invoices.constants';

const getFractionalDigits = (price: number) => {
  const digits = `${price}`.split('.');
  if (digits.length === 2) {
    const decimals = digits[1].length;
    return decimals < 3 ? 2 : 3;
  }
  return 2;
}

export const formatCurrency = (currency: string, money: number, fractional = false) => {
  if (money) {
    if (currency) {
      const cash = currency.toUpperCase();
      const options = {
        style: 'currency',
        currency: CURRENCY[cash].currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: fractional ? getFractionalDigits(money) : 2,
      };
      return new Intl.NumberFormat(CURRENCY[cash].locale, options).format(money);
    }
    return new Intl.NumberFormat('en-CA', {
      style: 'currency',
      currency: 'CAD',
    }).format(money);
  }
  return '$0';
};

export const formatPercent = (num: number) => {
  if (num) {
    return new Intl.NumberFormat('default', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 3,
    }).format(num);
  }
  return '';
};
