import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Stack, ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Permission from '../../../components/shared/permissions/Permission';
import { FeatureResource, ResourcePermission } from '../../../redux/models/feature.flags.models';
import { Board } from '../../../redux/models/board.models';
import EditableTitle from './EditableTitle';
import { getSettingByName, saveBoard, createGroup, selectGroupsByBoardId } from '../../../redux/slices/settings';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { SETTINGS_NAMES } from '../../../constants/core.constants';
import ButtonSpinner from '../../../components/shared/ButtonSpinner';
import { AppState } from '../../../redux/models/state.models';

const RENAME = 'Rename';
const ADD_GROUP = 'Add Group';
const SETTINGS = 'Settings';

const WritePermissions = {
  [FeatureResource.Board]: ResourcePermission.Write,
};

export default function BoardMenu({ board }: { board: Board }) {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const groups = useSelector((state: AppState) => selectGroupsByBoardId(state, board.entity_id));
  const title = board?.data?.name || '';
  const [name, setName] = useState(title);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const handleRename = async () => {
    if (board.data.name === name) {
      setIsEditing(false);
      return;
    }
    try {
      const updates: Board = {
        ...board,
        data: {
          ...board.data,
          name,
        },
      };
      const response = await saveBoard(updates);
      if (response.status < 300) {
        await dispatch(getSettingByName(SETTINGS_NAMES.BOARDS));
      } else {
        toast("Couldn't update board.", { type: 'error' });
      }
    } catch (error) {
      if (error instanceof Error) {
        toast(`Couldn't update board. ${error.message}`, { type: 'error' });
      }
    } finally {
      setIsEditing(false);
    }
  };

  const handleAddGroup = async () => {
    try {
      setIsSaving(true);
      const position = groups.length || 0;
      const response = await createGroup(position, board);
      if (response.status < 300) {
        await dispatch(getSettingByName(SETTINGS_NAMES.GROUPS));
      } else {
        toast("Couldn't add group.", { type: 'error' });
      }
    } catch (error) {
      if (error instanceof Error) {
        toast(`Couldn't add group. ${error.message}`, { type: 'error' });
      }
    } finally {
      setIsSaving(false);
    }
  };

  const handleOpenSettings = () => navigate(`${location.pathname}/settings`);

  useEffect(() => {
    const boardName = board?.data?.name || '';
    setName(boardName);
  }, [board]);

  return (
    <Stack direction="horizontal" gap={3}>
      <EditableTitle
        name={name}
        isEditing={isEditing}
        setName={setName}
        setIsEditing={setIsEditing}
        onRename={handleRename}
      />
      {!isEditing && !isSaving && (
        <Permission resources={WritePermissions}>
          <DropdownButton
            as={ButtonGroup}
            id="board-menu-dropdown-button"
            size="sm"
            variant="light"
            title="•••"
          >
            <Dropdown.Item onClick={() => setIsEditing(true)}>{RENAME}</Dropdown.Item>
            <Dropdown.Item onClick={handleAddGroup}>{ADD_GROUP}</Dropdown.Item>
            <Dropdown.Item onClick={handleOpenSettings}>{SETTINGS}</Dropdown.Item>
          </DropdownButton>
        </Permission>
      )}
      {isSaving && (
        <ButtonSpinner />
      )}
    </Stack>
  )
}
