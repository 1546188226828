import { Badge } from 'react-bootstrap';

function TripStatusCell({ status }: { status: string; }) {
  switch (status) {
    case 'ready-to-dispatch':
      return (
        <Badge bg="warning" className="badge-space capitalized">
          Ready To Dispatch
        </Badge>
      );
    case 'dispatched':
      return (
        <Badge bg="info" className="badge-space capitalized">
          {status}
        </Badge>
      );
    case 'driver-confirmed':
      return (
        <Badge bg="primary" className="badge-space capitalized">
          Driver Confirmed
        </Badge>
      );
    case 'complete':
      return (
        <Badge bg="success" className="badge-space capitalized">
          {status}
        </Badge>
      );
    default:
      return <div />;
  }
};

export default TripStatusCell;
