export const SHIPMENT_TABLE_HEADERS = [
  {
    name: 'Shipment No.',
    key: 'shipment_no',
  },
  {
    name: 'Customer',
    key: 'customer',
  },
  {
    name: 'Type',
    key: 'type',
  },
];

export const BILLING_SHIPMENT_TABLE_HEADERS = [
  {
    name: 'Date',
    key: 'start_date',
  },
  {
    name: 'Customer',
    key: 'customer',
  },
  {
    name: 'Shipment No.',
    key: 'shipment_no',
  },
  {
    name: 'Type',
    key: 'billing_type',
  },
  {
    name: 'PO #',
    key: 'purchase_order_no',
  },
  {
    name: 'Container #',
    key: 'container_no',
  },
  {
    name: 'Status',
    key: 'status',
  },
];
