/* eslint-disable @typescript-eslint/no-var-requires */
import * as Sentry from "@sentry/react";
import { VERSION_NO } from "../pages/version-updates/UpdateList";

const mixpanel = require('mixpanel-browser');

const isProduction = process.env.REACT_APP_APP_STAGE === 'production';
if (isProduction) mixpanel.init(process.env.REACT_APP_ANALYTICS_ID || '');

export default class Analytics {
  static identify(userId: string) {
    if (!isProduction) {
      return;
    }
    if (userId && userId !== '') {
      mixpanel.identify(userId);
    }
  }

  static track(event: string, org: string | undefined, props?: object) {
    if (!isProduction) {
      return;
    }
    const extras = props ? { ...props, version: VERSION_NO, company: org } : { version: VERSION_NO, company: org };
    mixpanel.track(event, extras);
  }

  static createShipment(org: string | undefined) {
    Analytics.track('Create Shipment', org);
  }

  static createNewTrip(org: string | undefined) {
    Analytics.track('Create Trip', org, {
      method: 'New',
    });
  }

  static sendFerryEmail(org: string | undefined) {
    Analytics.track('Send Ferry Email', org);
  }

  static sendETAEmail(org: string | undefined) {
    Analytics.track('Send ETA Email', org);
  }

  static createTemplatedTrip(org: string | undefined) {
    Analytics.track('Create Trip', org, {
      method: 'Templated',
    });
  }

  static createInvoice(org: string | undefined) {
    Analytics.track('Create Invoice', org);
  }

  static dispatchRoute(org: string | undefined, type: string) {
    Analytics.track('Dispatch Route', org, {
      type,
    });
  }

  static duplicateShipment(org: string | undefined, trips: boolean) {
    Analytics.track('Duplicate Shipment', org, {
      type: trips ? 'Shipment + Trips' : 'Shipment',
    });
  }

  static duplicateTrip(org: string | undefined, trips: boolean) {
    Analytics.track('Duplicate Trip', org, {
      type: trips ? 'Trip + Shipments' : 'Trip',
    });
  }

  static createDraft(org: string | undefined) {
    Analytics.track('Create Draft Dispatch', org);
  }

  static optimiseDispatch(org: string | undefined, greedy: boolean) {
    const type = greedy ? 'Greedy' : 'Evenly';
    Analytics.track('Optimise Dispatch', org, {
      type,
    });
  }

  static saveDraft(org: string | undefined) {
    Analytics.track('Save Draft Dispatch', org);
  }

  static applyDraft(org: string | undefined) {
    Analytics.track('Apply Draft Dispatch', org);
  }

  static dispatchDraft(org: string | undefined) {
    Analytics.track('Dispatch Draft Dispatch', org);
  }

  static createTripFromShipments(org: string | undefined) {
    Analytics.track('Create Trip From Shipments', org);
  }

  static saveTrip(org: string | undefined) {
    Analytics.track('Save Trip', org);
  }

  static saveShipment(org: string | undefined) {
    Analytics.track('Save Shipment', org);
  }

  static uploadDocument(org: string | undefined) {
    Analytics.track('Upload Document', org);
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  static capture(error: any) {
    if (!isProduction) console.log(error);
    if (isProduction && error instanceof Error) Sentry.captureException(error);
  }
}
