/* eslint-disable */
import React from 'react'
import { MoreVertical, MapPin, Flag } from 'react-feather'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { Column } from '../../models'
import EditableCell from "../cells/EditableCell";
import EditableCheckbox from '../cells/EditableCheckbox'
import EditableSelect from '../cells/EditableSelect'
import { MoveData } from '../../../../../redux/models/trip.models';
import { TripTableColumns } from '../../../../../redux/models/board.models';
import { DateService } from '../../../../../utils/dateService';
import DataRow from './DataRow';
import MoveTrailerCell from '../cells/MoveTrailerCell';
import MoveAddressCell from '../cells/MoveAddressCell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function MoveIcon({ pos, total }: { pos: number; total: number; }) {
  if (pos === 0) return <MapPin size={16} />
  if (pos === total - 1) {
    return (
      <FontAwesomeIcon
        width={36}
        height={36}
        icon={faMapMarkerAlt}
        className="text-secondary"
      />
    )
  }
  return <MoreVertical size={16} />
}

interface Props {
  move: MoveData;
  total: number;
  onUpdate: (subtaskId: string, key: string, value: any) => void;
  columns: TripTableColumns[];
};

function MoveRow({ move, onUpdate, columns, total }: Props) {
  const moveId = `${move.position}`;
  return (
    <tr className="bg-light">
      {columns.map((column) => {
        if (column.key === 'expand') {
          return (
            <td style={{ width: column.width }}>
              <div className="float-end">
                <MoveIcon pos={move.position || 0} total={total} />
              </div>
            </td>
          )
        }
        if (column.key === 'route') {
          return (
            <td style={{ width: column.width }}>
              <MoveAddressCell destinationId={move.destination_id || ''} />
            </td>
          )
        }
        if (column.key === 'trailer') {
          return (
            <td style={{ width: column.width }}>
              <MoveTrailerCell move={move} />
            </td>
          )
        }
        if (column.key === 'apt') {
          return (
            <td style={{ width: column.width }}>
              <div>{DateService.getHHmmFormat(move.scheduled_arrival_time)}</div>
            </td>
          )
        }
        if (column.key === 'expand') {
          return (
            <td style={{ width: column.width }}>
              <EditableSelect
                value={move.action}
                onChange={(value) => onUpdate(moveId, 'action', value)}
              />
            </td>
          )
        }
        if (column.entity === 'move') return <DataRow data={move} column={column} />
        return <td style={{ width: column.width }} />
      })}
    </tr>
  )
}

export default MoveRow