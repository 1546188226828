/* eslint-disable @typescript-eslint/no-explicit-any */
import { Badge } from 'react-bootstrap';

export const renderShipmentStatus = (status: string) => {
  switch (status) {
    case 'booked':
      return (
        <Badge bg="info" className="badge-space capitalized">
          {status}
        </Badge>
      );
    case 'missing-info':
      return (
        <Badge bg="danger" className="badge-space capitalized">
          Missing Info
        </Badge>
      );
    case 'completed':
      return (
        <Badge bg="primary" className="badge-space capitalized">
          {status}
        </Badge>
      );
    case 'invoiced':
      return (
        <Badge bg="success" className="badge-space capitalized">
          {status}
        </Badge>
      );
    case 'requested':
      return (
        <Badge bg="warning" className="badge-space capitalized">
          {status}
        </Badge>
      );
    case 'training':
      return (
        <Badge bg="secondary" className="badge-space capitalized">
          {status}
        </Badge>
      );
    default:
      return <div/>;
  }
};

export default function ShipmentsTableRow({ shipment, prop }: any) {
  if (prop === 'status') {
    return renderShipmentStatus(shipment.status);
  }
  return <div>{`${shipment[prop] || ''}`}</div>;
}
