import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Container } from 'react-bootstrap';

import useAppDispatch from '../../hooks/useAppDispatch';

import Header from './Header';
import ShipmentsTable from './ShipmentsTable/ShipmentsTable';
import NoShipmentMsg from './ShipmentsTable/NoShipmentMsg';
import Loader from '../../components/Loader';

import {
  loadShipments,
  selectAreShipmentsLoading,
  selectShipments,
} from '../../redux/slices/shipments';
import { selectIsSettingsLoaded, selectShipmentBoardSettings } from '../../redux/slices/settings';

import { LOADING_TEXT } from '../boards/constants/boards.constants';
import { BILLING_SHIPMENT_TABLE_HEADERS } from './ShipmentsTable/constants/shipmentTable.constants';
import UnassignedShipmentsTable from './ShipmentsTable/UnassignedShipmentsTable';

const NO_SHIPMENTS_MESSAGE = 'No shipments found';

function ShipmentList() {
  const dispatch = useAppDispatch();
  const [selectedShipments, setSelectedShipments] = useState<string[]>([]);
  const shipments = useSelector(selectShipments);
  const areShipmentsLoading = useSelector(selectAreShipmentsLoading);
  const boardSettings = useSelector(selectShipmentBoardSettings);
  const isBoardSettings = useSelector(selectIsSettingsLoaded);

  useEffect(() => {
    if (isBoardSettings) dispatch(loadShipments(boardSettings));
  }, [dispatch, isBoardSettings]);

  return (
    <>
      <Helmet title="Shipments" />
      <Container fluid className="p-0">
        <Header
          title="Shipments"
          selectedShipments={selectedShipments}
        />
        {areShipmentsLoading && <Loader text={LOADING_TEXT} />}
        {!areShipmentsLoading && (
          <UnassignedShipmentsTable />
        )}
        {shipments.length > 0 && !areShipmentsLoading ? (
          <div className="mt-4">
            <ShipmentsTable
              headers={BILLING_SHIPMENT_TABLE_HEADERS}
              noShipmentsMessage={NO_SHIPMENTS_MESSAGE}
              formattedShipments={shipments}
              selectedShipments={selectedShipments}
              setSelectedShipments={setSelectedShipments}
            />
          </div>
        ) : (
          <div className="mt-6">
            <NoShipmentMsg areShipmentsLoading={areShipmentsLoading} />
          </div>
        )}
      </Container>
    </>
  );
}

export default ShipmentList;