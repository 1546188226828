/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { FileText } from 'react-feather';

import {
  selectAddressesObject,
  selectDriversObject,
  selectBoardsObject,
  selectGroupsObject,
} from '../../../../../redux/slices/settings';
import useAppDispatch from '../../../../../hooks/useAppDispatch';
import { formatTrips } from './utils/tripSummaryTable.utils';

import { TRIP_SUMMARY_TABLE_HEADERS } from './constants/tripSummaryTable.constants';
import { saveShipmentDetails } from '../../../../../redux/slices/shipments';
import {
  saveShipmentDetails as saveShipmentDetailsPaginated
} from '../../../../../redux/slices/shipment-list/shipment-list';
import { TripListViewData } from '../../../../../redux/models/trip.models';
import { Shipment } from '../../../../../redux/models/shipment.models';
import useFeatureFlags from '../../../../../hooks/useFeatureFlags';

const NO_TRIPS_MESSAGE = 'No trips for this group';

interface Props {
  isEditable: boolean;
  trips: TripListViewData[];
  shipmentDetails: Shipment;
  showDocuments: boolean;
  openDocuments: (trip: TripListViewData) => void;
}

export default function TripSummaryTable({
  trips = [], shipmentDetails, isEditable, showDocuments, openDocuments
}: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const features = useFeatureFlags();
  const addressesObject = useSelector(selectAddressesObject);
  const driversObject = useSelector(selectDriversObject);
  const boardsObject = useSelector(selectBoardsObject);
  const groupsObject = useSelector(selectGroupsObject);
  const isPaginationEnabled = features.paginated_shipments?.is_enabled || false;
  const formattedTrips = formatTrips(trips, addressesObject, driversObject, boardsObject, groupsObject);

  const openTripPage = async (id: string) => {
    if (shipmentDetails && isEditable) {
      if (isPaginationEnabled) {
        await dispatch(saveShipmentDetailsPaginated(shipmentDetails, false));
      } else {
        await dispatch(saveShipmentDetails(shipmentDetails, false));
      }
    }
    navigate(`/${orgCode}/trips/${id}`);
  };
  const handleOpenDocs = (id: string) => {
    const trip = trips.find((tr) => tr.entity_id === id);
    if (trip) openDocuments(trip);
  };
  return (
    formattedTrips.length ? (
      <Table hover className="trips-table" data-cy="trip-summary-table">
        <thead>
          <tr>
            {TRIP_SUMMARY_TABLE_HEADERS.map((header) => <th key={header.key}>{header.name}</th>)}
          </tr>
        </thead>
        <tbody>
          {formattedTrips.map((trip: any, i: number) => {
            const tripData = trips.find((tr) => tr.entity_id === trip.id);
            const moves = tripData?.moves || [];
            const moveDocs = moves.find((mv) => {
              const docs = mv.documents || [];
              return docs.length > 0;
            })
            const isDocs = moveDocs !== undefined;
            return (
              <tr
                key={trip.id}
                id={`shipmentDetails-tripsTable__trip-${i}`}
                className="trips-table-row"
              >
                {TRIP_SUMMARY_TABLE_HEADERS.map((header) => (
                  <td
                    key={`${trip.id}-${header.key}`}
                    onClick={() => openTripPage(trip.id)}
                    data-cy={`shipmentDetails-tripsTable__trip-${i}-${header.key}`}
                  >
                    {trip[header.key]}
                  </td>
                ))}
                {showDocuments && (
                  <td>
                    {isDocs && (
                      <Button variant='outline-secondary' onClick={() => handleOpenDocs(trip.id)}><FileText /></Button>
                    )}
                  </td>
                )}
              </tr>
            )
          })}
        </tbody>
      </Table>
    ) : <div>{NO_TRIPS_MESSAGE}</div>
  );
}
