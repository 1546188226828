export const INVOICE_TABLE_HEADERS = [
  {
    name: 'Date',
    key: 'date',
  },
  {
    name: 'Invoice No.',
    key: 'invoice_no',
  },
  {
    name: 'Customer',
    key: 'customer',
  },
  {
    name: 'Shipment No.',
    key: 'shipment_no',
  },
  {
    name: 'Status',
    key: 'status',
  },
];

export const INVOICE_LINE_ITEMS = [
  {
    name: 'Name',
    key: 'name',
  },
  {
    name: 'Shipment No',
    key: 'shipment_no',
  },
  {
    name: 'QTY',
    key: 'qty',
  },
  {
    name: 'Unit',
    key: 'unit',
  },
  {
    name: 'Rate',
    key: 'rate',
  },
  {
    name: 'Total',
    key: 'total',
  },
];
