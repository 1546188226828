/* eslint-disable */
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import MoveRow from './MoveRow'
import { TripBoardModel } from '../../../../../redux/models/trip.models'
import { TripTableColumns } from '../../../../../redux/models/board.models'
import TripCell from './TripCell';
import { ArrowRightCircle } from 'react-feather';
import { Stack } from 'react-bootstrap';

interface Props {
  trip: TripBoardModel;
  boardDate: string;
  isDateRange: boolean;
  onUpdate: (taskId: string, key: string, value: any, subtaskId?: string) => void;
  onMoveExpand: (id: string) => void;
  onToggleExpand: (id: string) => void;
  isMovesExpanded: boolean;
  isShipmentsExpanded: boolean;
  columns: TripTableColumns[];
  onDispatch: (id: string) => void;
}

function TripRow({ trip, boardDate, isDateRange, onUpdate, onMoveExpand, onToggleExpand, isMovesExpanded, isShipmentsExpanded, columns, onDispatch }: Props) {
  const { orgCode } = useParams();
  const navigate = useNavigate();
  const [isMenuShowing, setIsMenuShowing] = useState(false);
  const moves = trip.moves || [];
  const numMoves = moves.length || 0;
  const isExpanded = isMovesExpanded || isShipmentsExpanded;
  const handleOpen = () => {
    navigate(`/${orgCode}/trips/${trip.id}`);
  }
  return (
    <>
      <tr>
        {columns.map((column, idx) => {
          if (idx === 1) {
            return (
              <td
                style={{ width: column.width }}
                key={column.key}
                onMouseEnter={() => setIsMenuShowing(true)}
                onMouseLeave={() => setIsMenuShowing(false)}
              >
                <Stack direction="horizontal" gap={2}>
                  <TripCell
                    key={column.key}
                    column={column}
                    trip={trip}
                    isExpanded={isExpanded}
                    boardDate={boardDate}
                    isDateRange={isDateRange}
                    onToggleExpand={onToggleExpand}
                    onMoveExpand={onMoveExpand}
                    onDispatch={onDispatch}
                  />
                  {isMenuShowing && (
                    <div onClick={handleOpen} style={{ cursor: 'pointer' }}>
                      <ArrowRightCircle size={18} />
                    </div>
                  )}
                </Stack>
              </td>
            )
          }
          return (
            <td style={{ width: column.width }} key={column.key}>
              <TripCell
                key={column.key}
                column={column}
                trip={trip}
                isExpanded={isExpanded}
                boardDate={boardDate}
                isDateRange={isDateRange}
                onToggleExpand={onToggleExpand}
                onMoveExpand={onMoveExpand}
                onDispatch={onDispatch}
              />
            </td>
          )
        })}
      </tr>
      {isMovesExpanded && moves.map(move => (
        <MoveRow
          key={`${move.position}`}
          move={move}
          onUpdate={(subtaskId, key, value) => onUpdate(trip.id, key, value, subtaskId)}
          columns={columns}
          total={numMoves}
        />
      ))}
    </>
  )
}

export default TripRow;