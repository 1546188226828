import { useSelector } from "react-redux";
import { MoveData } from "../../../../../redux/models/trip.models";
import { selectFleetObject } from "../../../../../redux/slices/settings";
import { mapTrailerName } from "../../../../planner/TripTable/utils/tripTable.utils";

export default function MoveTrailerCell({ move }: { move: MoveData }) {
  const trailers = useSelector(selectFleetObject);
  const trailerIds = move.trailer_ids || [];
  const names = trailerIds.reduce((store, id) => {
    const name = mapTrailerName(id, trailers);
    if (store === '') {
      return name;
    }
    return `${store}, ${mapTrailerName}`;
  }, '');
  return <div>{`${names}`}</div>;
}
